import { Divider, Tabs } from 'antd';
import axios from 'axios';
import LoadingScreen from 'components/LoadingScreen';
import { MdTypography } from 'components/global';
import { useEffect, useState } from 'react';
import emptyTableImg from '../../../asset/images/flat-color-icons_template.svg';
import MdLandingPageTemplateCard from './MdLandingPageTemplateCard';
import { MdEmptyBox } from 'pages/ContactPage/Icons/MdEmptyBox';
import { fetchTemplates } from 'pages/stripoEditor/utils';

const MdLandingPageTemplateSelection = ({ submitTemplateSelected, typeOfMailParam = 'speditor' }) => {
  const { TabPane } = Tabs;
  // eslint-disable-next-line no-unused-vars
  const [tabKey, setTabKey] = useState('1');
  const [currentCard] = useState(null);
  const [loading, setLoading] = useState({ id: '' });
  const [isTemplateLoading, setIsTemplateLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [fetchDefaultTemplates, setFetchDefaultTemplates] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [mySavedFetchTemplates, setMySavedFetchTemplates] = useState([]);
  const [items, setItems] = useState([]);

  const mailType = typeOfMailParam;

  useEffect(() => {
    setIsTemplateLoading(true);
    (async () => {
      try {
        await fetchTemplates(setItems, setIsTemplateLoading, emptyTableImg);
        const resp2 = await axios.get(`/templates/user?type=${'speditor'}`);
        setFetchDefaultTemplates([]);
        setIsTemplateLoading(false);
        if (resp2?.status) {
          setMySavedFetchTemplates(resp2.data.data);
          return;
        }
        setMySavedFetchTemplates([]);
      } catch (error) {
        console.log({ error });
      }
    })();
  }, [mailType]);

  // const myTemplates = mySavedFetchTemplates.map((obj, index) => ({
  //   _id: index,
  //   typeOfMail: obj.typeOfMail,
  //   body: obj.strippoHtml,
  //   content: obj.strippoCss,
  //   previewImage: obj?.previewImage || emptyTableImg,
  //   isFirst: true,
  // }));

  return (
    <div className="mail_subject__template_select" data-test="template-list-selection">
      <Tabs onTabClick={(key) => setTabKey(key)}>
        <TabPane tab={<MdTypography variant={'h6Light'}>Templates</MdTypography>} key="2">
          {items.length < 1 && isTemplateLoading ? (
            <LoadingScreen />
          ) : (
            <>
              {items.length < 1 ? (
                <div className="w-full emptyBox">
                  <MdEmptyBox />
                  <div className="emptyText">
                    We are bringing in free reusable templates for you to create beautiful emails soon
                  </div>
                </div>
              ) : (
                <div>
                  <div className="template-section">
                    <div className="mb-8 template-grid">
                      {items?.map((temp, index) => {
                        return (
                          <MdLandingPageTemplateCard
                            key={index}
                            id={temp._id}
                            icon={temp.previewImage}
                            title={temp.title}
                            data-index={index}
                            submitTemplateSelected={submitTemplateSelected}
                            temp={temp}
                            data-test="template-card"
                            currentCard={currentCard}
                            setLoading={setLoading}
                            loading={loading}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </TabPane>
        {/* <TabPane tab={<MdTypography variant={'h6Light'}>My Saved Templates</MdTypography>} key="3">
          {isTemplateLoading ? (
            <LoadingScreen />
          ) : (
            <>
              {!mySavedFetchTemplates.length ? (
                <div className="w-full emptyBox">
                  <MdEmptyBox />
                  <div className="emptyText">You have not created any template yet</div>
                </div>
              ) : (
                <div className="my-8 template-grid">
                  {myTemplates.map((temp, index) => {
                    return (
                      <MdLandingPageTemplateCard
                        key={index}
                        id={temp._id}
                        tabKey="3"
                        icon={temp.previewImage}
                        data-index={index}
                        temp={temp}
                        submitTemplateSelected={submitTemplateSelected}
                        fetchedTemplate
                        currentCard={currentCard}
                        setLoading={setLoading}
                        loading={loading}
                      />
                    );
                  })}
                </div>
              )}
            </>
          )}
        </TabPane> */}
      </Tabs>
      <Divider />
    </div>
  );
};

export default MdLandingPageTemplateSelection;
