import { Spin, Typography } from 'antd';
import DeleteIcon from 'asset/Icons/DeleteIcon';
import EditLandingPageIcon from 'asset/Icons/EditLandingPageIcon';
import { MdGlobalButton, MdTypography } from 'components/global';
import { LoadingOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { showToastMessage } from 'utils/Toast';
import { deleteLandingPageApi, toggleLandingPageApi } from 'utils/service';

export const MdLandingPageListItem = ({ data, styles }) => {
  const webUrl = window.location.origin;
  const { Paragraph } = Typography;
  const [loading, setLoading] = useState({ id: '', type: '' });
  const [isDeleted, setIsDeleted] = useState(false);

  const landingPageId = data?._id;

  const [published, setPublished] = useState(data.published);

  const togglePublishStatusFn = () => {
    setLoading({ id: '', type: '' });
    const newToggleState = !published; // Determine the new state

    setPublished(newToggleState);

    toggleLandingPageApi(landingPageId, newToggleState === true ? 'publish' : 'unpublish')
      .then(() => {
        setLoading({ id: '', type: '' });
        showToastMessage({
          type: 'success',
          title: 'Success',
          description: 'Your action was successful',
        });
      })
      .catch((e) => {
        setLoading({ id: '', type: '' });
        setPublished(!newToggleState);
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: e?.message,
        });
      });
  };

  const deleteLandingPageFn = () => {
    setIsDeleted(true);
    setLoading({ id: data?._id, type: 'delete' });
    deleteLandingPageApi(data?._id)
      .then(() => {
        setLoading({ id: '', type: '' });
        showToastMessage({
          type: 'success',
          title: 'File',
          description: 'Landing page deleted successfully',
        });
      })
      .catch((e) => {
        setLoading({ id: '', type: '' });
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: e || 'Error deleting landing page',
        });
      });
  };

  if (isDeleted) {
    return null; // If the item is deleted, remove it from the UI
  }

  return (
    <div className={styles.content_container}>
      <div className={styles.part_one}>
        <p data-test="landing-page-title">{data?.title}</p>
        <div className={!published ? styles.publishStatus : styles.status}>
          {published === true ? 'published' : 'unpublished'}
        </div>
      </div>
      <div className={styles.part_two}>
        <Paragraph
          copyable={{
            icon: [
              <div
                key="copy-icon"
                style={{
                  fontFamily: 'Paralucent-Medium',
                  color: '#5264f5',
                  marginTop: '1rem',
                  marginRight: '1rem',
                  textDecorationColor: '#5264f5',
                  textDecoration: 'underline',
                  textDecorationThickness: '.5px',
                }}
              >
                Copy url
              </div>,
              <div
                key="copied-icon"
                style={{
                  fontFamily: 'Paralucent-Medium',
                  marginTop: '1rem',
                  marginRight: '1rem',
                  color: '#5264f5',
                  textDecorationColor: '#5264f5',
                  textDecorationThickness: '.5px',
                }}
              >
                Copied
              </div>,
            ],
            tooltips: false,
            text: data?.page_url || `${webUrl}/landing-page/${data?._id}`,
          }}
        />{' '}
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Link to={`/landing-pages/${data?._id}/edit`}>
          <EditLandingPageIcon />
        </Link>
        &nbsp;&nbsp;&nbsp;
        {loading?.id === data?._id && loading?.type === 'delete' ? (
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        ) : (
          <DeleteIcon onClick={deleteLandingPageFn} data-test="delete-landing-page" />
        )}
        &nbsp;&nbsp;&nbsp;
        <MdGlobalButton
          id={'primary_lg'}
          className={published === true ? styles.unpublishedStatus : styles.publishedStatus}
          htmlType="submit"
          dataTestId="toggle-landing-page"
          style={{ width: '125px', marginLeft: '10px' }}
          onClick={togglePublishStatusFn}
        >
          {loading?.id === data?._id && loading?.type === 'publish' ? (
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
          ) : (
            <MdTypography
              variant={'h6Medium'}
              dataTestId="toggle-button-text"
              style={{
                color: published === true ? '#717171' : '#5264f5',
                fontSize: '14px',
              }}
            >
              {published === true ? 'Unpublish' : 'Publish'}
            </MdTypography>
          )}
        </MdGlobalButton>
      </div>
    </div>
  );
};
