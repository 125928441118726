import { STRIPO_CONSTANT } from 'pages/stripoEditor/utils';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  useCreateNewEmailMutation,
  useEditStrippoEmailMutation,
  useGetStrippoMailDataQuery,
  useSendStrippoTestMailMutation,
} from 'redux/featureApi/stripoApiSlice';
import { showToastMessage } from 'utils/Toast';
import { errorMessage } from 'utils/getErrors';

const withStrippoEditor = (Component) => {
  return (props) => {
    const [imLoading, setImLoading] = useState({ id: STRIPO_CONSTANT.editorLoading });
    const [files, setFiles] = useState([]);

    const { mailId, campaignId, landingPageId } = useParams();
    const { search } = useLocation();
    // const forInstantEmail = new URLSearchParams(search).get('action');
    const template = new URLSearchParams(search).get('template');
    const sub = new URLSearchParams(search).get('sub');

    const [createNewEmail] = useCreateNewEmailMutation();
    const [updateStrippoEmail] = useEditStrippoEmailMutation();
    const [sendStrippoTestMail] = useSendStrippoTestMailMutation();
    const { data, isLoading } = useGetStrippoMailDataQuery(
      { mailId: mailId || landingPageId },
      { refetchOnMountOrArgChange: true }
    );

    const compileStrippoTemplate = async () => {
      const { html, css } = await new Promise((resolve, reject) => {
        window.StripoApi.getTemplate((html, css) => {
          resolve({ html, css });
        });
      });

      const juicedHtml = await new Promise((resolve, reject) => {
        window.StripoApi.compileEmail((_error, juicedHtml) => {
          resolve(juicedHtml);
        });
      });

      return { html, css, juicedHtml };
    };

    const handleSaveEmailToDB = async (value) => {
      setImLoading((prevData) => ({ ...prevData, id: STRIPO_CONSTANT.isSavingMail }));

      try {
        const { html, css, juicedHtml } = await compileStrippoTemplate();

        let result;

        if (landingPageId) {
          const resp = await updateStrippoEmail({
            mailId: landingPageId,
            body: {
              strippoHtml: html,
              strippoCss: css,
              strippoJuicedHtml: juicedHtml,
              body: juicedHtml,
              attachments: files,
              saveTemplate: false, // hardcoding this will still have to be changed
              isLandingPage: true,
            },
          }).unwrap();

          result = { email: resp.data.email, strippoCss: css, strippoHtml: html, strippoJuicedHtml: juicedHtml };
        }

        if (mailId || campaignId) {
          const resp = await updateStrippoEmail({
            mailId: mailId,
            body: {
              strippoHtml: html,
              strippoCss: css,
              strippoJuicedHtml: juicedHtml,
              body: juicedHtml,
              attachments: files,
              saveTemplate: value,
            },
          }).unwrap();

          // console.log({ resp });
          result = { email: resp.data.email, strippoCss: css, strippoHtml: html, strippoJuicedHtml: juicedHtml };
        } else {
          const mail = await createNewEmail({ html, css, juicedHtml }).unwrap();
          // console.log({ mail });
          result = { email: mail.data.email, strippoCss: css, strippoHtml: html, strippoJuicedHtml: juicedHtml };
        }

        setImLoading((prevData) => ({ ...prevData, id: STRIPO_CONSTANT.initial }));
        return result;
      } catch (error) {
        const errMsg = errorMessage(error);
        const spamDetected = error?.data?.error.includes('spam');
        showToastMessage({
          type: 'error',
          title: spamDetected ? 'Spam Detected' : 'Error',
          description: spamDetected ? error?.data?.error : errMsg,
        });
        setImLoading((prevData) => ({ ...prevData, id: STRIPO_CONSTANT.initial }));
        throw new Error(spamDetected ? error?.data?.error : errMsg);
      }
    };

    const sendTestMailHandler = async () => {
      setImLoading((prevData) => ({ ...prevData, id: STRIPO_CONSTANT.isSendingTestMail }));
      // Get the juiced html format of the email
      try{
        const { juicedHtml } = await compileStrippoTemplate();
        
        const attachments = files?.filter((file) => file.status !== 'ERROR').map((file) => file);
        sendStrippoTestMail({
          body: { subject: data?.email?.subject, body: juicedHtml, attachments },
          emailId: data.email._id,
        })
          .unwrap()
          .then((mail) => {
            showToastMessage({
              type: 'success',
              title: 'Test Mail',
              description: mail?.data?.data?.message || 'Test mail has been sent to your registered email address',
            });
          })
          .catch((e) => {
            const errMsg = errorMessage(e);
            showToastMessage({
              type: 'error',
              title: 'Error',
              description: errMsg,
            });
          })
          .finally(() => setImLoading((prevData) => ({ ...prevData, id: STRIPO_CONSTANT.initial })));
      }catch(error){
        console.log(error)
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: 'An error occured, Please try again',
        });
      }
    };

    useEffect(()=>{
      if(isLoading) setImLoading({ id: STRIPO_CONSTANT.editorLoading, editorLoaded: false  })
    }, [isLoading])

    return (
      <Component
        {...props}
        campaignId={campaignId}
        mailId={mailId}
        landingPageId={landingPageId}
        handleSaveEmailToDB={handleSaveEmailToDB}
        template={template}
        sub={sub}
        setImLoading={setImLoading}
        imLoading={imLoading}
        data={data}
        files={files}
        setFiles={setFiles}
        sendTestMailHandler={sendTestMailHandler}
      />
    );
  };
};

export default withStrippoEditor;
