import axios from 'axios';

const getTemplateUrl = (title = '', variant = '', type = 'html', sub = '') => {
  const template = `https://raw.githubusercontent.com/ardas/stripo-plugin/master/Public-Templates/${variant}-Templates/${sub}/`;
  const remodifiedTitle = title === 'Fathers-day-newsletter' ? 'Fathers-Day' : title;
  return encodeURI(`${template}${title}/${remodifiedTitle}.${type}`);
};

const generateTemplateList = (title, type, variant = 'Basic', sub = '', fetchedTemplate = false, template = {}) => {
  const obj = {
    title,
    category: type,
    sub,
    htmlTemplate: fetchedTemplate ? template.html : getTemplateUrl(title, variant, 'html', sub),
    cssTemplate: fetchedTemplate ? template.css : getTemplateUrl(title, variant, 'css', sub),
    imageUrl:
      title === 'New-editor-template'
        ? 'https://test-maily-attachments.s3.us-east-2.amazonaws.com/650979eba07be80014ae26cb-1709140614360_screenshot.png'
        : getTemplateUrl(title, variant, 'png', sub),
    fetchedTemplate,
  };

  if (fetchedTemplate) {
    obj.imageUrl = template.previewImage;
    obj.templateId = template.id;
  }

  return obj;
};

const remoteTemplateList = [
  { title: 'New-editor-template', variant: 'Basic', type: 'quick' },
  // { title: 'Empty-Template', variant: 'Basic', type: 'quick' },
  // { title: '1-Column-Template', variant: 'Basic', type: 'quick' },
  // { title: '2-Column-Template', variant: 'Basic', type: 'quick' },
  // { title: 'Promo newsletter mockup', variant: 'Basic', type: 'newsletter' },
  // { title: 'Trigger newsletter mockup', variant: 'Basic', type: 'newsletter' },
  // { title: 'Fathers-day-newsletter', variant: 'Custom', sub: 'Cars', type: 'newsletter' },
  // { title: 'Promo-newsletter', variant: 'Custom', sub: 'Cars', type: 'newsletter' },
  { title: 'Trigger-newsletter', variant: 'Custom', sub: 'Cars', type: 'newsletter' },
  { title: 'Trigger-newsletter', variant: 'Custom', sub: 'Fashion', type: 'newsletter' },
  // { title: 'Promo-newsletter', variant: 'Custom', sub: 'Fashion', type: 'newsletter' },
  // { title: 'Black-friday', variant: 'Custom', sub: 'Fashion', type: 'newsletter' },
  // { title: 'Christmas', variant: 'Custom', sub: 'Finance', type: 'newsletter' },
  // { title: 'Promo-newsletter', variant: 'Custom', sub: 'Finance', type: 'newsletter' },
  // { title: 'Trigger-newsletter', variant: 'Custom', sub: 'Finance', type: 'newsletter' },
  // { title: 'Promo-newsletter', variant: 'Custom', sub: 'Restaurants', type: 'newsletter' },
  { title: 'Trigger-newsletter', variant: 'Custom', sub: 'Restaurants', type: 'newsletter' },
  { title: 'Thanksgiving', variant: 'Custom', sub: 'Restaurants', type: 'newsletter' },
  // { title: 'Independence-Day', variant: 'Custom', sub: 'Sport', type: 'newsletter' },
  // { title: 'Promo-newsletter', variant: 'Custom', sub: 'Sport', type: 'newsletter' },
  // { title: 'Trigger-newsletter', variant: 'Custom', sub: 'Sport', type: 'newsletter' },
];

const landingPageRemoteTemplateList = [
  // { title: 'Fathers-day-newsletter', variant: 'Custom', sub: 'Cars', type: 'newsletter' },
  { title: 'Trigger-newsletter', variant: 'Custom', sub: 'Restaurants', type: 'newsletter' },
  { title: 'Thanksgiving', variant: 'Custom', sub: 'Restaurants', type: 'newsletter' },
];

const fetchCustomTemplates = async () => {
  try {
    const response = await axios.get('/templates');

    if (response.status !== 200) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    const data = await response.data.data;

    // Map the response to the structure expected by generateTemplateList
    const fetchedTemplates = data.map((item) =>
      generateTemplateList(item.name, item.subCategory || 'quick', '', item.sub || 'Custom', true, item)
    );

    return fetchedTemplates;
  } catch (error) {
    console.error('Error fetching templates:', error.message);
    return [];
  }
};

export const templateList = (
  window.location.pathname.includes('landing-page') ? landingPageRemoteTemplateList : remoteTemplateList
).map(({ title, type, variant, sub }) => generateTemplateList(title, type, variant, sub));

export const getTemplateList = async () => {
  const customTemplates = await fetchCustomTemplates();
  const list = window.location.pathname.includes('landing-page') ? landingPageRemoteTemplateList : remoteTemplateList;
  return [
    ...list.map(({ title, type, variant, sub }) => generateTemplateList(title, type, variant, sub)),
    ...customTemplates,
  ];
};

export const STRIPO_CONSTANT = {
  initial: '',
  editorLoading: 'stripo-editor',
  isSavingMail: 'saving-stripo-mail',
  isSendingTestMail: 'sending-stripo-test-mail',
};

export const fetchTemplates = async (setItems, setIsTemplateLoading, emptyTableImg) => {
  const templateList = await getTemplateList(); // Await the async response
  const templates = templateList.map((obj) => ({
    _id: obj.templateId,
    key: obj.sub,
    title: obj.title,
    category: obj.category,
    typeOfMail: 'speditor',
    body: obj.htmlTemplate,
    content: obj.cssTemplate,
    type: obj.type,
    previewImage: obj?.imageUrl || emptyTableImg,
    fetchedTemplate: obj.fetchedTemplate,
    isFirst: true,
  }));
  setItems(templates);
  setIsTemplateLoading(false);
};
