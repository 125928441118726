import { CheckCircleFilled, LoadingOutlined } from '@ant-design/icons';
import { Form, Input, Modal, Popover, Spin, Switch, Tooltip, Typography } from 'antd';
import { ReactComponent as ActiveMailIcon } from '../asset/Icons/bi_send-check.svg';
import { ReactComponent as CollapseAllIcon } from '../asset/Icons/carbon_collapse-all.svg';
import { ReactComponent as InActiveMailIcon } from '../asset/Icons/fluent_mail-pause-20-regular.svg';
import { MdGlobalButton, MdTabWithTable, MdTypography } from '../components/global';

import useDebounce from '../hooks/useDebounce';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';
import {
  useAddNewCampaignMutation,
  useGetUserCampaignsQuery,
  useSetCampaignStatusMutation,
} from '../redux/featureApi/campaignApiSlice';
import 'rsuite/dist/styles/rsuite-default.css';
import '../styles/campaigne.scss';
import { deleteCampaign, fetchCampaigns } from '../utils/service.js';
import { showToastMessage } from '../utils/Toast';
import shareIcon from '../asset/Icons/shareIcon.png';
import trash from '../asset/Icons/trash.png';
import { MdCampaignHeader } from '../components/global/MdCampaignHeader/MdCampaignHeader';
import moment from 'moment';
import { LaptopMan } from '../asset/Icons/LaptopMan';
import DeleteModal from 'components/Modal/DeleteModal';
import Xlogo from '../asset/Icons/Xlogo';
import { cleanString } from 'utils/generics';

function MdCampaigns() {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');
  const [cardResponse] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [dateValue, setDateValue] = useState('');
  const [campaignListKept, setCampaignListKept] = useState([]);
  const [campaignList, setCampaignList] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [statusView, setStatusView] = useState('All');
  const [billingModal, setBillingModal] = useState(false);
  const closeBillingModal = () => setBillingModal(false);
  const [imVisible, setImVisible] = useState({ id: '' });
  const [imLoading, setImLoading] = useState({ id: '' });

  const [currentPage, setCurrentPage] = useState(1); // currentPage should be passed in the query parameter
  const totalDcuments = campaignList?.length; // This should be gotten from the api response as  the total number of instantEmailData the user has

  const webUrl = window.location.origin;
  const { Paragraph } = Typography;
  const [createNewId] = useSearchParams();
  const newId = createNewId.get('new_campaign');
  const [createNewCampaignForm] = Form.useForm();

  const { user } = JSON.parse(localStorage.getItem('userDetails'));
  const userName = user.username;

  function fetchAllCampaigns() {
    setImLoading({ id: 'loadingCampaigns' });
    fetchCampaigns(
      '',
      '',
      {
        cancelLoading: () => setImLoading({ id: '' }),
      },
      setCampaignList,
      setCampaignListKept
    );
  }
  /* eslint-disable */
  const {
    data: campaignListData,
    isLoading: isCampaignListLoading,
    isSuccess: isCampaignListFetched,
    isFetching: isCampaignListFetching,
    refetch,
  } = useGetUserCampaignsQuery();
  useEffect(() => {
    // fetchAllCampaigns();
    if (!isCampaignListFetching && isCampaignListFetched) {
      setCampaignList(campaignListData?.data?.campaigns);
      setCampaignListKept(campaignListData?.data?.campaigns);
    }
  }, [isCampaignListFetching]);

  useEffect(() => {
    refetch();
  }, []);

  //create campaign with rtk-query
  const [createCampaign, { isLoading: isCreatingCampaign }] = useAddNewCampaignMutation();

  const [setCampaignStatus, { isLoading: isSetCampaignStatus }] = useSetCampaignStatusMutation();

  const debouncedSearchVal = useDebounce(searchValue, 500);

  useEffect(() => {
    if (debouncedSearchVal) {
      fetchCampaigns(
        'search',
        debouncedSearchVal,
        {
          cancelLoading: () => setImLoading({ id: '' }),
        },
        setCampaignList
      );
    } else {
      setCampaignList(campaignListData?.data?.campaigns);
      setImLoading({ id: '' });
    }
  }, [debouncedSearchVal]);

  const toggle = (campaignId) => {
    setImLoading({ campaignId });
    setCampaignStatus({ campaignId })
      .then(({ data: { data } }) => {
        showToastMessage({
          type: data.status ? 'success' : 'warning',
          title: data.status ? 'Activated' : 'Deactivated',
          description: `Your campaign has been successfully ${data.status ? 'activated' : 'deactivated'}`,
        });
        const newList = campaignListKept.map((data) => {
          if (data._id === campaignId) {
            return { ...data, status: !data.status };
          } else {
            return data;
          }
        });
        setCampaignList(newList);
        setCampaignListKept(newList);
        setImLoading({ campaignId: '' });
      })
      .catch((err) => {
        console.log('err', err);
        setImLoading({ campaignId: '' });
        const errObject = err.response.data;
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: errObject.message || 'Unable to process request',
        });
      });
  };

  const changeListView = async (val) => {
    let tempData;
    if (val === 'active') {
      setStatusView(val);
      tempData = campaignListKept.filter((each) => each.status === true);
      setCampaignList([...tempData]);
    } else if (val === 'inactive') {
      setStatusView(val);
      tempData = campaignListKept.filter((each) => each.status !== true);
      setCampaignList([...tempData]);
    } else {
      setStatusView(val);
      setDateValue('');
      setSearchValue('');
      setCampaignList(campaignListKept);
    }
  };

  const routeToStripe = () => {
    closeBillingModal();
    window.open(cardResponse, '_blank');
  };

  const deleteCampaignFunc = (campaignId) => {
    deleteCampaign(campaignId)
      .then(({ data }) => {
        if (data.success) {
          setImLoading({ id: '' });
          refetch();
          // const newCampaignList = campaignList.filter((campaign) => campaign._id !== campaignId);
          // setCampaignList(newCampaignList);
          // setCampaignListKept(newCampaignList);
          showToastMessage({
            type: 'success',
            title: 'Campaign Deleted',
            description: data.data.message,
          });
        }
      })
      .catch((error) => {
        setImLoading({ id: '' });
        const errorObject = error.response.data;
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: errorObject.message || 'Oops! An error occured',
        });
      });
  };

  const columns = [
    {
      title: 'Title',
      width: 250,
      render: ({ name, createdAt, _id: id }) => {
        const url = newId === id ? `/campaign/${id}/emails` : `/campaign/${id}/campaign-info`;
        return (
          <div>
            <Link to={url}>
              <p className="campaignTableTitle">{name}</p>
            </Link>
            <h4 className="campaignTableTitleSub">Launched {moment.utc(createdAt).format('ddd MMM, Do')}</h4>
          </div>
        );
      },
    },
    {
      title: 'Email(s)',
      width: 100,
      dataIndex: 'emails',
      render: (emails) => (
        <div>
          <p className="campaignTableSubTitle">Emails</p>
          <h4 className="campaignTableTitle">{emails}</h4>
        </div>
      ),
    },
    {
      title: 'Recipients',
      width: 100,
      dataIndex: 'recipientsCount',
      render: (recipientsCount) => (
        <div>
          <p className="campaignTableSubTitle">Recipients</p>
          <h4 className="campaignTableTitle">{recipientsCount}</h4>
        </div>
      ),
    },
    {
      title: 'Conversion Rate',
      width: 180,
      dataIndex: 'conversionRate',
      render: (conversionRate) => (
        <div>
          <p className="campaignTableSubTitle">Conversion Rate</p>
          <h4 className="campaignTableTitle">{conversionRate}%</h4>
        </div>
      ),
    },
    {
      title: 'Delivery Rate',
      width: 150,
      dataIndex: 'deliveryRate',
      render: (deliveryRate) => (
        <div>
          <p className="campaignTableSubTitle">Delivery Rate</p>
          <h4 className="campaignTableTitle">{deliveryRate}%</h4>
        </div>
      ),
    },
    {
      title: 'Open Rate',
      width: 150,
      dataIndex: 'openRate',
      render: (openRate) => (
        <div>
          <p className="campaignTableSubTitle">Open Rate</p>
          <h4 className="campaignTableTitle">{openRate}%</h4>
        </div>
      ),
    },
    {
      title: 'Status',
      width: 50,
      render: ({ _id: campaignId, status }) => (
        <div
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <Switch
            loading={imLoading.campaignId === campaignId && isSetCampaignStatus}
            checked={status}
            onChange={() => toggle(campaignId)}
            className="campSwitch"
          />
        </div>
      ),
    },
    {
      title: 'Action',
      width: 150,
      render: ({ _id: id, name }) => (
        <div className="flex actionIcons">
          <div className="pointer deleteIcon">
            <Tooltip
              onClick={(event) => {
                event.stopPropagation();
              }}
              placement="topLeft"
              color="red"
              title={'Delete Campaign? This action cannot be undone'}
            >
              {imLoading.id === id ? (
                <Spin indicator={<LoadingOutlined />} />
              ) : (
                <div
                  onClick={() => {
                    setImVisible({ id: 'deletingCampaignModal' });
                    setImLoading({ id });
                  }}
                >
                  <img src={trash} alt="trash icon" />
                </div>
              )}
            </Tooltip>
          </div>
          <div data-test="campaignIcons" className="pointer primaryColorLight ml-3 ">
            <Popover
              content={
                <div
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                  className="flex"
                >
                  <FacebookShareButton url={`${webUrl}/join/${id}`} title={'Facebook'}>
                    <img className="mr-3" src="/assets/facebookIcon.svg" alt="icon"></img>
                  </FacebookShareButton>
                  <TwitterShareButton url={`${webUrl}/join/${id}`} title={'Twitter'}>
                    <Xlogo className="mr-3" />
                  </TwitterShareButton>
                  <LinkedinShareButton url={`${webUrl}/join/${id}`} title={'Linkedin'}>
                    <img className="mr-3" src="/assets/linkedinIcon.svg" alt="icon"></img>
                  </LinkedinShareButton>
                  <Paragraph
                    style={{ margin: '0px' }}
                    data-test="url-share-icon"
                    copyable={{
                      icon: [
                        <img key="copy-icon" src="/assets/copyIcon.svg" alt="icon"></img>,
                        <CheckCircleFilled key="copied-icon" />,
                      ],
                      text: `${webUrl}/${userName}/join/${cleanString(name, '-')}?id=${id}`,
                      tooltips: ['Copy url', 'Url copied to clipboard'],
                    }}
                  ></Paragraph>
                </div>
              }
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <img src={shareIcon} alt="share icon" />
            </Popover>
          </div>
        </div>
      ),
    },
  ];

  const DeleteModalx = () => {
    return (
      <Modal
        title="Delete Campaign"
        className="emmaccen deleting-campaign-modal"
        open={imVisible.id === 'deletingCampaignModal'}
        onOk={() => {
          setImVisible({ id: '' });
          deleteCampaignFunc(imLoading.id);
        }}
        onCancel={() => {
          setImLoading({ id: '' });
          setImVisible({ id: '' });
        }}
        centered
      >
        <div className="emmaccen">
          <p>Are you sure you want to delete this Campaign?</p>
        </div>
      </Modal>
    );
  };

  const CreateNewCampaign = (name) => {
    const campaignTitle = campaignListData?.data?.campaigns?.map((item) => item.name);
    const campaignFormValue = createNewCampaignForm.getFieldValue().name.trim(' ');
    const campaignExist = campaignTitle.some((campaign) => campaign.toLowerCase() === campaignFormValue.toLowerCase());

    if (campaignExist) {
      showToastMessage({
        type: 'error',
        title: 'Error',
        description: 'The campaign title already exists',
      });
    } else if (!campaignExist) {
      createCampaign({ name })
        .unwrap()
        .then((result) => {
          const id = result.data.campaign._id;
          setImVisible({ id: '' });
          showToastMessage({
            type: 'success',
            title: 'Campaign Created',
            description: 'Campaign created successfully',
          });
          createNewCampaignForm.resetFields();
          navigate(`/campaign/${id}/campaign-info`);
          //refresh campaign list
          fetchAllCampaigns();
        })
        .catch((e) => {
          showToastMessage({
            type: 'error',
            title: 'Error',
            description: e.response?.data?.message,
          });
        });
    }
  };

  const CreateNewCampaignModel = () => {
    return (
      <Modal
        open={imVisible.id === 'createNewCampaign'}
        onCancel={() => setImVisible({ id: '' })}
        footer={null}
        className="emmaccen create-campaign-modal"
        centered
      >
        <div>
          <MdTypography variant={'h5Medium'} className="text-center">
            Create Campaign
          </MdTypography>
          <div className="modalAlert">
            <div>
              <MdTypography variant={'btnLight'} className="modalText">
                A campaign is a series of emails that are delivered to a user in a specific order. These emails are
                carefully crafted and designed to engage your subscribers and guide them through a journey
              </MdTypography>
            </div>
            <div className="modalImg">
              <div className="illustration">{<LaptopMan />}</div>
            </div>
          </div>
          <div data-test="campaign-input">
            <Form
              form={createNewCampaignForm}
              name="basic"
              onFinish={({ name, number, seconds }) => CreateNewCampaign(name, number, seconds)}
              size="large"
              onFinishFailed={() => null}
            >
              <label className="mb-2 formLabel">Campaign title</label>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Please give your campaign a name',
                    whitespace: true,
                  },
                ]}
              >
                <Input data-test="create-campaign-input" />
              </Form.Item>

              <div className="centered mt-10">
                <MdGlobalButton
                  id={'primary_lg'}
                  type="primary"
                  htmlType="submit"
                  loading={isCreatingCampaign}
                  dataTestId="create-campaign"
                  data-test="create-campaign-button"
                >
                  <MdTypography variant={'h6Medium'} style={{ color: '#ffffff' }}>
                    Create Campaign
                  </MdTypography>
                </MdGlobalButton>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    );
  };

  const menuItems = [
    {
      key: 'all',
      label: 'All',
      icon: <CollapseAllIcon />,
    },
    {
      key: 'active',
      label: 'Active',
      icon: <ActiveMailIcon />,
    },
    {
      key: 'inactive',
      label: 'Inactive',
      icon: <InActiveMailIcon />,
    },
  ];

  const onRow = ({ _id: id }) => {
    const url = newId === id ? `/campaign/${id}/emails` : `/campaign/${id}/campaign-info`;
    return {
      onClick: () => {
        navigate(url);
      },
    };
  };

  return (
    <div className="h-full">
      <>
        {imVisible.id === 'createNewCampaign' && <CreateNewCampaignModel />}
        <DeleteModal
          open={imVisible.id === 'deletingCampaignModal'}
          deleteFunction={() => {
            setImVisible({ id: '' });
            deleteCampaignFunc(imLoading.id);
          }}
          onCancel={() => {
            setImLoading({ id: '' });
            setImVisible({ id: '' });
          }}
          header="Campaign"
          description="This campaign will be permanently deleted and cannot be recovered"
        />

        {imLoading.id === 'loadingCampaigns' ? (
          <div className="centered">
            <Spin indicator={<LoadingOutlined />} />
          </div>
        ) : (
          <div className="emmaccen">
            <MdCampaignHeader
              title="Campaigns"
              description="Create a series of emails to effectively nurture leads and guide potential customers towards making a purchase."
              headerIllustration={<LaptopMan />}
              imLoading={imLoading}
              setImLoading={setImLoading}
              setSearchValue={setSearchValue}
              setImVisible={setImVisible}
            />
          </div>
        )}
        <MdTabWithTable
          onClick={({ key }) => changeListView(key)}
          menuItems={menuItems}
          campaignList={campaignList}
          isDataLoading={isCampaignListLoading || isCampaignListFetching}
          columns={columns}
          onRow={onRow}
          pagination={{
            current: currentPage,
            total: totalDcuments,
            pageSize: 10, // Default is 10 and it is actually not needed , pageSize / Limit can be controlled from what ever we pass in the query parameter
            onChange: (page) => {
              setCurrentPage(page);
            },
          }}
        />
      </>
      <Modal
        title="Add Card"
        open={billingModal}
        onOk={routeToStripe}
        onCancel={closeBillingModal}
        centered
        footer={[
          <button
            key="back"
            className="btn btn-outline-blue mr-2"
            onClick={() => {
              closeBillingModal();
            }}
          >
            Cancel
          </button>,
          <button key="submit" className="btn btn-blue" type="primary" onClick={routeToStripe}>
            Add Card
          </button>,
        ]}
      >
        <div className="text-center">
          <p className="mb-3">
            You are currently on a free plan with a limit of 100 mails.
            <br /> click "Add Card" to add your Card to your billing information. Don't worry, we won't charge you
            unless you select a plan.
          </p>
          <p>Click "cancel" to continue with your free plan</p>
        </div>
      </Modal>
    </div>
  );
}

export default MdCampaigns;
