import React, { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Select, Form, Button } from 'antd';
import { useAddNewBulkContactToGroupMutation } from 'redux/featureApi/contactApiSlice';
import { MdTypography } from 'components/global';
import { showToastMessage } from 'utils/Toast';
import CsvKeyValidator from 'components/emmaccen/modals/instantEmailUploadCsvModal/CsvKeyValidator';
import { SET_NEW_JOB } from 'contexts/job/reducer/types';
import { jobContext } from 'contexts/job';
import { getJobDetails } from 'utils/service';
import Cookies from 'js-cookie';
import style from 'styles/newContactPage.module.scss';
import InfoIcon from 'asset/Icons/InfoIcon';

const MdConfirmCsvUpload = ({
  saveContactForm,
  instantForm,
  csvResult,
  uploadContactsTo,
  campaignId,
  setUploadStep,
  group,
  setGroup,
  refetchContactGroupsData,
  refetchSingleInstantEmail,
  groupDetails,
  createContactGroup
}) => {
  const { Option } = Select;
  const { dispatch } = useContext(jobContext);
  const [addNewBulkContactToGroup, { isLoading: creatingContact }] = useAddNewBulkContactToGroupMutation();
  const [MdCsvUploadForm] = Form.useForm();
  const [isCreatingGroup, setIsCreatingGroup] = useState(false);

  // Safely extract and validate CSV headers
  const csvFoundHeaders = useMemo(() => {
    if (!Array.isArray(csvResult) || csvResult.length === 0) {
      return [];
    }

    try {
      // Get headers from all valid rows
      const headers = new Set();
      csvResult.forEach(row => {
        if (row && typeof row === 'object') {
          Object.keys(row).forEach(key => {
            const trimmedKey = key.trim();
            if (trimmedKey) headers.add(trimmedKey);
          });
        }
      });
      
      return Array.from(headers);
    } catch (error) {
      console.error('Error processing CSV headers:', error);
      showToastMessage({
        type: 'error',
        title: 'Error',
        description: 'Failed to process CSV headers',
      });
      return [];
    }
  }, [csvResult]);

  const checkUploadProgress = (jobId) => {
    if (!Cookies.get('token')) {
      return;
    }
  
    let isSubscribed = true;
  
    const interval = setInterval(async () => {
      if (!Cookies.get('token') || !isSubscribed) {
        clearInterval(interval);
        return;
      }
  
      try {
        const jobResponse = await getJobDetails(jobId);
        
        if (jobResponse?.data?.job?.status === 2) {
          clearInterval(interval);
          if (isSubscribed) {
            showToastMessage({
              type: 'success',
              title: 'Upload Complete',
              description: 'Contact import has finished processing',
            });
            await refetchContactGroupsData();
          }
        }
      } catch (error) {
        console.error('Error checking upload progress:', error);
        if (isSubscribed) {
          clearInterval(interval);
          showToastMessage({
            type: 'error',
            title: 'Error',
            description: 'Failed to check upload progress',
          });
        }
      }
    }, 5000);
  
    return () => {
      isSubscribed = false;
      clearInterval(interval);
    };
  };

  const validateContactData = (contact) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(String(contact.email).toLowerCase());
  };

  // Function to create a new group and then proceed with contact upload
  const onSubmitContactDetail = async ({ firstName, lastName, email }) => {
    try {
      setIsCreatingGroup(true);

      if (!groupDetails) {
        throw new Error('Group details not available');
      }

      if (!Array.isArray(csvResult) || csvResult.length === 0) {
        throw new Error('No CSV data available');
      }

      if (!email) {
        throw new Error('Email column must be selected');
      }

      // First create the group
      const groupResponse = await createContactGroup({ 
        payload: groupDetails
      }).unwrap();

      const newGroupId = groupResponse.data.group?._id;
      
      if (!newGroupId) {
        throw new Error('Failed to create contact group');
      }
      
      setGroup(newGroupId);

      // Process and validate contacts
      const convertResults = csvResult
        .filter(data => data && typeof data === 'object')
        .map((data) => ({
          firstName: String(data[firstName] || '').trim(),
          lastName: String(data[lastName] || '').trim(),
          email: String(data[email] || '').trim(),
        }))
        .filter(contact => contact.email && validateContactData(contact));

      if (convertResults.length === 0) {
        throw new Error('No valid contacts found in CSV');
      }

      const firstContact = convertResults[0];
      const firstRowValidator = new CsvKeyValidator(firstContact);
      
      if (!firstRowValidator.validateEmail()) {
        throw new Error('Invalid email format detected');
      }

      const { data } = await addNewBulkContactToGroup({
        payload: { contacts: convertResults, groupId: newGroupId },
      }).unwrap();

      dispatch({
        type: SET_NEW_JOB,
        payload: {
          ...data,
          ID: data._id,
        },
      });

      // Start progress monitoring
      const cleanup = checkUploadProgress(data._id);

      try {
        // Fetch updated groups
        const res = await refetchContactGroupsData().unwrap();
        await refetchSingleInstantEmail();
        
        // Safely get current selected groups
        const selectedGroups = saveContactForm.getFieldValue('groupList') || [];
        
        // Find the new group
        const newGroup = res?.groups?.find((grp) => grp?._id === newGroupId);
        
        if (newGroup?._id) {
          // Only add the new group if it's not already in the selection
          const newSelectedGroups = Array.from(new Set([...selectedGroups, newGroup._id]));
          saveContactForm.setFieldsValue({ groupList: newSelectedGroups });
        }
        
        instantForm.resetFields();
      } catch (error) {
        console.error('Error updating group selection:', error);
        // Continue with the flow even if group selection update fails
      }

      setUploadStep(2);

      showToastMessage({
        type: 'success',
        title: 'Upload Started',
        description: 'Contact import has been queued',
      });

      return cleanup;

    } catch (error) {
      showToastMessage({
        type: 'error',
        title: 'Error',
        description: error.message || 'Failed to upload contacts',
      });
    } finally {
      setIsCreatingGroup(false);
    }
  };

  // Early return if no valid headers found
  if (csvFoundHeaders.length === 0) {
    return (
      <div className={style.addNewContactModal}>
        <MdTypography variant={'h5Medium'} className="text-center w-full">
          No valid CSV headers found
        </MdTypography>
        <Button
          size={'large'}
          onClick={() => setUploadStep(0)}
          className={style['import-btn']}
          style={{ color: '#1C1C1C' }}
        >
          Back
        </Button>
      </div>
    );
  }

  return (
    <div className={style.addNewContactModal}>
      <div className="flex items-center">
        <MdTypography variant={'h5Medium'} className="text-center w-full">
          Tag your contacts
        </MdTypography>
      </div>
      <div className={`${style['instant-mail-alert']} my-4`}>
        <InfoIcon /> &nbsp;&nbsp; Match the titles in your upload with our predefined tags
      </div>

      <div>
        <Form
          form={MdCsvUploadForm}
          onFinish={onSubmitContactDetail}
          onFinishFailed={() => null}
          autoComplete="off"
          size="large"
          layout="vertical"
          requiredMark={false}
        >
          <Form.Item
            name="firstName"
            label="Match first name"
            data-test="match-first-name-email"
          >
            <Select
              showSearch
              placeholder="Select value for First Name"
              optionFilterProp="children"
              data-test="match-first-name-select-instant-email"
              filterOption={(input, option) => 
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {csvFoundHeaders.map((header, index) => (
                <Option 
                  key={index} 
                  value={header}
                  data-test="match-first-name-test-instant-email"
                >
                  {header}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="lastName"
            label="Match last name"
            data-test="match-last-name-email"
          >
            <Select
              showSearch
              placeholder="Select value for Last Name"
              optionFilterProp="children"
              data-test="match-last-name-select-instant-email"
              filterOption={(input, option) => 
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {csvFoundHeaders.map((header, index) => (
                <Option 
                  key={index} 
                  value={header}
                  data-test="match-last-name-test-instant-email"
                >
                  {header}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="email"
            label="Match email address"
            data-test="match-email-email"
            rules={[{ required: true, message: 'A column in your file must be matched with Email!' }]}
          >
            <Select
              showSearch
              placeholder="Select value for Email"
              optionFilterProp="children"
              data-test="match-email-select-instant-email"
              filterOption={(input, option) => 
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {csvFoundHeaders.map((header, index) => (
                <Option 
                  key={index} 
                  value={header}
                  data-test="match-email-test-instant-email"
                >
                  {header}
                </Option>
              ))}
            </Select>
          </Form.Item>
          
          <div className="emmaccen pt-4">
            <div className="flex-space-btw actionBtns">
              <Button
                size={'large'}
                type=""
                onClick={() => setUploadStep(0)}
                className={style['import-btn']}
                style={{ color: '#1C1C1C' }}
              >
                Back
              </Button>
              <Button
                size={'large'}
                data-test="match-btn-test-csv-instant-email"
                type="primary"
                htmlType="submit"
                loading={isCreatingGroup || creatingContact}
                className={`${style['instant-mail-btn']} mt-0 w-270`}
              >
                Create group
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

MdConfirmCsvUpload.propTypes = {
  uploadContactsTo: PropTypes.oneOf(['createNewContactGroup', 'campaignRecipient']).isRequired,
  saveContactForm: PropTypes.object.isRequired,
  instantForm: PropTypes.object.isRequired,
  csvResult: PropTypes.array.isRequired,
  campaignId: PropTypes.string,
  setUploadStep: PropTypes.func.isRequired,
  group: PropTypes.string,
  setGroup: PropTypes.func.isRequired,
  refetchContactGroupsData: PropTypes.func.isRequired,
  refetchSingleInstantEmail: PropTypes.func.isRequired,
  groupDetails: PropTypes.object,
  createContactGroup: PropTypes.func.isRequired
};

export default MdConfirmCsvUpload;