import { Divider, Tabs } from 'antd';
import axios from 'axios';
import LoadingScreen from 'components/LoadingScreen';
import { MdTypography } from 'components/global';
import { createTemplateaArray, newStrippoTemplateArray } from 'pages/grapeEditor/MockedTemplateArray';
import { useEffect, useState } from 'react';
import createTempImg from '../../../asset/images/create-template.svg';
import emptyTableImg from '../../../asset/images/flat-color-icons_template.svg';
import uploadCodeImg from '../../../asset/images/upload-code.svg';
import TemplateCard from './TemplateCard';
import { MdEmptyBox } from 'pages/ContactPage/Icons/MdEmptyBox';
import { fetchTemplates } from 'pages/stripoEditor/utils';

export function updateCategories(arr) {
  return [...new Set(arr.map((item) => item.category))];
}

const TemplateSelection = ({
  submitTemplateSelected,
  submissionIsLoading,
  selectedPlateObj,
  setSelectedPlateObj,
  typeOfMailParam,
  isLoading,
}) => {
  const { TabPane } = Tabs;
  // eslint-disable-next-line no-unused-vars
  const [tabKey, setTabKey] = useState('1');
  const [currentCard] = useState(null);
  const [loading, setLoading] = useState({ id: '' });
  const [isTemplateLoading, setIsTemplateLoading] = useState(true);
  const [fetchDefaultTemplates, setFetchDefaultTemplates] = useState([]);
  const [mySavedFetchTemplates, setMySavedFetchTemplates] = useState([]);
  const [items, setItems] = useState([]);
  const [templateCategories, setTemplatesCategories] = useState(updateCategories(items));
  const mailType = typeOfMailParam;

  useEffect(() => {
    setIsTemplateLoading(true);
    (async () => {
      // const resp = await axios.get(`/templates/default?typeOfMail=${mailType}`);
      try {
        await fetchTemplates(setItems, setIsTemplateLoading, emptyTableImg);
        const resp2 = await axios.get(`/templates/user?type=${mailType}`);
        setFetchDefaultTemplates([]);
        setIsTemplateLoading(false);
        if (resp2?.status) {
          setMySavedFetchTemplates(resp2.data.data);
          return;
        }
        setMySavedFetchTemplates([]);
      } catch (error) {
        console.log({ error });
      }
    })();
  }, [mailType]);

  useEffect(() => {
    fetchTemplates(setItems, setIsTemplateLoading, emptyTableImg);
  }, []);

  useEffect(() => {
    setTemplatesCategories(updateCategories(items));
  }, [items]);

  const myTemplates = mySavedFetchTemplates.map((obj, index) => ({
    _id: index,
    typeOfMail: obj.typeOfMail,
    body: obj.strippoHtml,
    content: obj.strippoCss,
    previewImage: obj?.previewImage || emptyTableImg,
    isFirst: true,
  }));

  const [ordered, setOrdered] = useState([]);
  useEffect(() => {
    if (myTemplates) {
      setOrdered(myTemplates.reverse());
    }
    // eslint-disable-next-line
  }, [mySavedFetchTemplates]);

  return (
    <div className="mail_subject__template_select" data-test="template-list-selection">
      <Tabs onTabClick={(key) => setTabKey(key)}>
        <TabPane tab={<MdTypography variant={'h6Light'}>Templates</MdTypography>} key="2">
          {items.length < 1 && isTemplateLoading ? (
            <LoadingScreen />
          ) : (
            <>
              {typeOfMailParam === 'speditor' ? (
                // <div className="w-full emptyBox">
                //   <MdEmptyBox />
                //   <div className="emptyText">
                //     We are bringing in free reusable templates for you to create beautiful emails soon
                //   </div>
                // </div>
                <>
                  {templateCategories.length < 1 ? (
                    <div className="w-full emptyBox">
                      <MdEmptyBox />
                      <div className="emptyText">
                        We are bringing in free reusable templates for you to create beautiful emails soon
                      </div>
                    </div>
                  ) : (
                    templateCategories.map((category) => (
                      <div className="template-section" key={category}>
                        <div className="mb-3">
                          <MdTypography variant={'h6Medium'} className="capitalize">{category}</MdTypography>
                        </div>
                        <div className="mb-8 template-grid">
                          {items
                            .filter((item) => item.category === category)
                            .map((temp, index) => (
                              <TemplateCard
                                key={index}
                                id={temp._id}
                                icon={temp.previewImage}
                                title={temp.title}
                                data-index={index}
                                submitTemplateSelected={submitTemplateSelected}
                                temp={temp}
                                currentCard={currentCard}
                                setLoading={setLoading}
                                loading={loading}
                              />
                            ))}
                        </div>
                      </div>
                    ))
                  )}
                </>
              ) : (
                <>
                  {!fetchDefaultTemplates.length ? (
                    <div className="w-full">
                      <img className="mx-auto" src={emptyTableImg} alt="" />
                    </div>
                  ) : (
                    <div className="templatePane  flex flex-wrap overflow-y-auto">
                      {fetchDefaultTemplates.map((temp, index) => {
                        return (
                          <TemplateCard
                            key={index}
                            id={temp._id}
                            tabKey="2"
                            icon={temp.previewImage}
                            data-index={index}
                            selectedPlateObj={selectedPlateObj}
                            submissionIsLoading={submissionIsLoading}
                            onClick={(e) => {
                              if (e.target.classList.contains('temp_overlay')) {
                                setSelectedPlateObj(temp);
                                submitTemplateSelected(temp);
                              }
                            }}
                            currentCard={currentCard}
                          />
                        );
                      })}
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </TabPane>

        <TabPane
          tab={
            <MdTypography variant={'h6Light'} dataTestId="saved-templates-test">
              My Saved Templates
            </MdTypography>
          }
          key="3"
        >
          {isTemplateLoading ? (
            <LoadingScreen />
          ) : (
            <>
              {!mySavedFetchTemplates.length ? (
                <div className="w-full emptyBox">
                  <MdEmptyBox />
                  <div className="emptyText">You have not created any template yet</div>
                </div>
              ) : (
                <div className="my-8 template-grid">
                  {ordered.map((temp, index) => {
                    return (
                      <TemplateCard
                        key={index}
                        id={temp._id}
                        tabKey="3"
                        icon={temp.previewImage}
                        data-index={index}
                        temp={temp}
                        submitTemplateSelected={submitTemplateSelected}
                        savedTemplate={true}
                        currentCard={currentCard}
                        setLoading={setLoading}
                        loading={loading}
                      />
                    );
                  })}
                </div>
              )}
            </>
          )}
        </TabPane>
        <TabPane
          tab={
            <MdTypography variant={'h6Light'} dataTestId="create-new-strippo">
              Create New
            </MdTypography>
          }
          key="1"
        >
          <div className="templatePane mt-8 flex flex-wrap overflow-y-auto" data-test="create-new-strippo-card">
            {typeOfMailParam === 'speditor' ? (
              newStrippoTemplateArray.map((temp, index) => (
                <TemplateCard
                  key={index}
                  id={temp._id}
                  temp={temp}
                  icon={temp.previewImage}
                  title={temp.title}
                  dataTest={`create-new-strippo-card-box-${index}`}
                  submitTemplateSelected={submitTemplateSelected}
                  currentCard={currentCard}
                  setLoading={setLoading}
                  loading={loading}
                  isPreviewVisible={false}
                  emptyTemplate={true}
                />
              ))
            ) : createTemplateaArray.length === 0 ? (
              <>
                <TemplateCard id="003" icon={createTempImg} title="" />
                <TemplateCard id="004" icon={uploadCodeImg} title="" />
                <TemplateCard id="005" icon={emptyTableImg} title="" />
              </>
            ) : (
              createTemplateaArray.map((temp, index) => {
                return (
                  <TemplateCard
                    key={index}
                    id={temp._id}
                    tabKey="1"
                    icon={temp.previewImage}
                    title={temp.title}
                    selectedPlateObj={selectedPlateObj}
                    submissionIsLoading={submissionIsLoading}
                    onClick={(e) => {
                      setSelectedPlateObj(temp);
                      submitTemplateSelected(temp);
                    }}
                    setLoading={setLoading}
                    loading={loading}
                    // fetchedTemplate

                    currentCard={currentCard}
                  />
                );
              })
            )}
          </div>
        </TabPane>
      </Tabs>
      <Divider />
    </div>
  );
};

export default TemplateSelection;
