import { Spin, Typography } from 'antd';
import { useState } from 'react';
import DeleteLandingPageIcon from 'asset/Icons/DeleteLandingPageIcon';
import EditLandingPageIcon from 'asset/Icons/EditLandingPageIcon';
import { MdGlobalButton, MdTypography } from 'components/global';
import { LoadingOutlined } from '@ant-design/icons';
import React from 'react';
import { Link } from 'react-router-dom';
import { toggleOptInPageApi } from 'utils/service';
import { showToastMessage } from 'utils/Toast';

const { Paragraph } = Typography;

export const OptInPageListItem = ({ datas, styles, setLoading, loading, webUrl, deleteOptInPage }) => {
  const [isToggled, setIsToggled] = useState(datas?.published);
  const [showDropdown, setShowDropdown] = useState(false);

  const togglePublish = (datas) => {
    setLoading({ id: datas._id, type: 'publish' });
    const newToggleState = !isToggled;
    const newData = {...datas}
    setIsToggled(newToggleState);

    toggleOptInPageApi(datas?._id, newToggleState === true ? 'publish' : 'unpublish', 
      newToggleState === true ? datas : (newData.published = false),
    )
      .then(() => {
        setLoading({ id: '', type: '' });
        showToastMessage({
          type: 'success',
          title: 'Success',
          description: 'Your action was successful',
        });
      })
      .catch((e) => {
        setLoading({ id: '', type: '' });
        setIsToggled(!newToggleState);
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: e?.message,
        });
      });
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <div className={styles.content_container}>
      <div className={styles.part_one}>
        <p data-test="title">{datas?.title}</p>
        <div className={!isToggled ? styles.publishStatus : styles.status}>
          {isToggled === true ? 'published' : 'unpublished'}
        </div>
        <button onClick={toggleDropdown} className={styles.dropdown_toggle}>
          <span className={styles.dots}>•••</span>
        </button>
      </div>
      <div className={`${styles.part_two} ${showDropdown ? styles.show_dropdown : ''}`}>
        <div className={styles.dropdown_content}>
          <Paragraph
            copyable={{
              icon: [
                <div
                  key="copy-icon"
                  style={{
                    fontFamily: 'Paralucent-Medium',
                    color: '#5264f5',
                    marginTop: '1rem',
                    marginRight: '1rem',
                    textDecorationColor: '#5264f5',
                    textDecoration: 'underline',
                    textDecorationThickness: '.5px',
                  }}
                >
                  Copy url
                </div>,
                <div
                  key="copied-icon"
                  style={{
                    fontFamily: 'Paralucent-Medium',
                    marginTop: '1rem',
                    marginRight: '1rem',
                    color: '#5264f5',
                    textDecorationColor: '#5264f5',
                    textDecorationThickness: '.5px',
                  }}
                >
                  Copied
                </div>,
              ],
              tooltips: false,
              text: datas?.page_url || `${webUrl}/${datas?.title}/${datas?._id}`,
            }}
          />
          <Link
            data-test="edit-optin-page"
            to={`/opt-in-pages/${datas.title}/${datas._id}/edit-opt-in-page?template_id=${datas?.template_id || '1'}`}
          >
            <EditLandingPageIcon />
          </Link>
          {loading?.id === datas?._id && loading?.type === 'delete' ? (
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
          ) : (
            <DeleteLandingPageIcon deleteOptInPage={deleteOptInPage} id={datas?._id} />
          )}
          <MdGlobalButton
            id={'primary_lg'}
            className={isToggled === true ? styles.unpublishedStatus : styles.publishedStatus}
            htmlType="submit"
            data-test="toggle-optin-page"
            style={{ width: '125px', marginLeft: '10px' }}
            onClick={() => togglePublish(datas)}
          >
            {loading?.id === datas?._id && loading?.type === 'publish' ? (
              <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
            ) : (
              <MdTypography
                variant={'h6Medium'}
                style={{
                  color: isToggled === true ? '#717171' : '#5264f5',
                  fontSize: '14px',
                }}
              >
                {isToggled === true ? 'Unpublish' : 'Publish'}
              </MdTypography>
            )}
          </MdGlobalButton>
        </div>
      </div>
    </div>
  );
};