import { Form, Select, Tabs } from 'antd';
import { MailOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { useState, useEffect, useRef, useCallback } from 'react';
import { MdGlobalButton, MdTypography } from 'components/global';
import { containValidEmail } from 'utils/formRules';
import Cancels from 'asset/Icons/cancels';
import { CreateNewGroup } from './CreateNewGroup';
import { showToastMessage } from 'utils/Toast';

const Step1Subscribers = ({
  saveContactForm,
  setImVisible,
  imVisible,
  contactGroups,
  setContactGroups,
  refetchContactGroupsData,
  refetchSingleInstantEmail,
  fetchingContactGroups,
  groupsAdded,
  setGroupsAdded,
  subscribersCount,
  savedGroup,
  savedGroupsToInstantEmailsLoading,
  handleSaveInstantEmailContact,
  addedRecipients,
}) => {
  const { Option } = Select;
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [activeTab, setActiveTab] = useState('email');
  const [csvResult, setCsvResult] = useState(null);
  const [group, setGroup] = useState(null);
  const inputRef = useRef();
  const [emailData, setEmailData] = useState('');
  const [addedMail, setAddedMail] = useState([]);
  const [disable, setDisable] = useState(false);
  const [emailLimit, setEmailLimit] = useState(0);
  const [validMail, setValidMail] = useState(false);
  // const [recipRef] = useState([]);
  const [selectedValue, setSelectedValue] = useState([...savedGroup]);

  const filterGroups = useCallback((groups) => {
    return groups?.filter((group) => !(group?._id === 'risky_contacts' || group?._id === 'all_contacts'));
  }, []);

  const filterAndSetGroups = useCallback(() => {
    const currentGroups = saveContactForm.getFieldValue('groupList') || [];
    setGroupsAdded(currentGroups.length > 0);

    if (contactGroups) {
      const filtered = filterGroups(contactGroups);
      setFilteredGroups(filtered.reverse());
    }
  }, [contactGroups, filterGroups, saveContactForm, setGroupsAdded]);

  useEffect(() => {
    filterAndSetGroups();
  }, [filterAndSetGroups]);

  useEffect(() => {
    if (group) {
      const currentGroups = saveContactForm.getFieldValue('groupList') || [];
      const groupId = group._id || (Array.isArray(group) && group.length > 0 ? group[0]._id : null);
      
      if (groupId && !currentGroups.includes(groupId)) {
        const newGroups = [...currentGroups, groupId];
        saveContactForm.setFieldsValue({ 
          groupList: newGroups
        });
        setGroupsAdded(newGroups.length > 0);
        if (contactGroups) {
          const groupExists = contactGroups.some(existingGroup => existingGroup._id === groupId);
          
          if (!groupExists) {
            const updatedContactGroups = [...contactGroups, group];
            setContactGroups(updatedContactGroups);
            
            const filtered = filterGroups(updatedContactGroups);
            setFilteredGroups(filtered.reverse());
          } else {
            filterAndSetGroups();
          }
        }
      }
    }
  }, [group, saveContactForm, setGroupsAdded, contactGroups, setContactGroups, filterGroups, filterAndSetGroups]);

  useEffect(() => {
    if (savedGroup) {
      const savedGroupIds = Array.isArray(savedGroup) ? 
        savedGroup.map(group => group._id) : 
        (savedGroup._id ? [savedGroup._id] : []);
        
      if (savedGroupIds.length > 0) {
        saveContactForm.setFieldsValue({ 
          groupList: savedGroupIds
        });
        setGroupsAdded(true);
      }
    }
  }, [savedGroup, saveContactForm, setGroupsAdded]);

  useEffect(() => {
    if (addedRecipients && addedRecipients.length > 0) {
      setAddedMail(addedRecipients);
    }
  }, [addedRecipients]);

  const handleChange = (value) => {
    if (selectedValue.length > 0) {
      setGroupsAdded(true);
      setSelectedValue((pre) => [...pre, ...value]);
    } else {
      setGroupsAdded(value.length > 0); 
      setSelectedValue(value);
    }
  };

  const handleMailData = (e) => {
    let value = e.target.value;
    setEmailData(value);
  };

  useEffect(() => {
    const isValidEmail = containValidEmail[0].pattern.test(emailData);
    setValidMail(isValidEmail);
    if (isValidEmail) {
      setDisable(true);
    }
  }, [emailData])

  useEffect(() => {
    if (emailData.includes(',')) {
      const emails = emailData
        .split(',')
        .map((email) => email.trim())
        .filter((email) => email !== '');

      const validEmails = emails.filter((email) => containValidEmail[0].pattern.test(email));
      const invalidEmails = emails.filter((email) => !containValidEmail[0].pattern.test(email));

      if (invalidEmails.length > 0) {
        showToastMessage({
          type: 'error',
          title: 'Invalid emails',
          description: `Invalid emails: ${invalidEmails.join(', ')}`,
        });
      }

      if (validEmails.length > 0) {
        const currentCount = addedMail.length;
        const remaining = 100 - currentCount;
        const toAdd = validEmails.slice(0, remaining);
        const overLimit = validEmails.length - remaining;

        if (toAdd.length > 0) {
          setAddedMail((prev) => [...prev, ...toAdd]);
          setEmailLimit(currentCount + toAdd.length);
        }

        if (overLimit > 0) {
          showToastMessage({
            type: 'error',
            title: 'Limit exceeded',
            description: `Only ${toAdd.length} emails added. Limit is 100.`,
          });
        }

        setEmailData('');
      }
    }
  }, [emailData, addedMail]);

  useEffect(() => {
    if (groupsAdded === true || addedMail?.length > 0 || validMail) {
      setDisable(true);
    } else if (groupsAdded === false || addedMail?.length === 0) {
      setDisable(false);
    }
  }, [groupsAdded, addedMail, validMail]);

  useEffect(() => {
    if (addedMail && addedMail?.length > 0) {
      setEmailLimit(addedMail?.length);
    }
  }, [addedMail]);

  const removeSingleMail = (indexToRemove) => {
    setAddedMail((prevItems) => prevItems.filter((item, index) => index !== indexToRemove));
    setEmailLimit(addedMail?.length - 1);
  };

  const handleSubmitform = (groupList) => {
    if (emailData) {
      if (containValidEmail[0].pattern.test(emailData)) {
        const emailToAdd = emailData.trim();
        const uniqueEmails = new Set([...addedMail, emailToAdd]);
        handleSaveInstantEmailContact(groupList, Array.from(uniqueEmails));
        setEmailData('');
      } else {
        showToastMessage({
          type: 'error',
          title: 'Invalid email',
          description: 'Please enter a valid email address.',
        });
        return;
      }
    } else {
      handleSaveInstantEmailContact(groupList, addedMail);
    }
  };

  return (
    <Form
      form={saveContactForm}
      name="basic"
      onFinish={({ groupList }) => handleSubmitform(groupList)}
      onFinishFailed={() => null}
      autoComplete="off"
      size="large"
      className="formOne"
      layout="vertical"
    >
      <CreateNewGroup
        imVisible={imVisible}
        setImVisible={setImVisible}
        contactGroups={filteredGroups}
        setCsvResult={setCsvResult}
        csvResult={csvResult}
        refetchSingleInstantEmail={refetchSingleInstantEmail}
        refetchContactGroupsData={refetchContactGroupsData}
        setContactGroups={setContactGroups}
        saveContactForm={saveContactForm}
        group={group}
        setGroup={setGroup}
      />

      <Tabs activeKey={activeTab} onChange={setActiveTab} style={{ marginBottom: '-70px' }}>
        <Tabs.TabPane
          tab={
            <span style={{ backgroundColor: '#F8F8F8', padding: '5px' }}>
              <MailOutlined /> Add Email Address
            </span>
          }
          key="email"
        >
          <div style={{ width: '300px', marginTop: '15px' }}>
            <div className="flex items-center gap-1 my-1">
              <MdTypography variant={'btnRegular'}>Add email address</MdTypography>
              <span className="text-[#F2F2F2]">
                (Single or multiple) <span className={`${emailLimit >= 100 ? 'text-red-400' : null}`}>{emailLimit}/100</span>
              </span>
            </div>
            <div className={`${emailLimit >= 100 ? 'border-2 border-red-400 rounded-md' : 'border border-black'} flex flex-wrap`}>
              {addedMail?.length !== 0 && (
                <div className="flex flex-wrap gap-1 p-1">
                  {addedMail?.map((item, index) => (
                    <p
                      key={index}
                      className={`${validMail ? null : 'bg-red-400'} p-1 flex items-center border rounded-sm`}
                      style={{ backgroundColor: '#F2F2F2' }}
                      data-test="single-mail-name"
                    >
                      {item}
                      <span 
                        onClick={() => removeSingleMail(index)} 
                        className="mx-2 flex items-center mt-1"
                        data-test="close-test"
                      >
                        <Cancels height={20} width={20} />
                      </span>
                    </p>
                  ))}
                </div>
              )}
              <input
                onChange={handleMailData}
                value={emailData}
                placeholder="Enter email addresses separated by commas"
                id="sinlge"
                className="w-full"
                readOnly={emailLimit >= 100}
                ref={inputRef}
                data-test="email-input"
              />
              <span className={`px-3 ${validMail || emailData === '' ? 'hidden text-[12px]' : 'block text-red-400 text-[8px]'}`}>
                Enter a valid mail
              </span>
            </div>
            <div
              className=" my-2 p-2 rounded-md"
              style={{ fontSize: '11px', backgroundColor: '#FFF9E6', border: '1px solid #FFECB2' }}
            >
              <p>
                Recipients that do not belong to any contact group will be automatically added to “All contacts” and will be
                visible in your saved contacts
              </p>
            </div>
          </div>
        </Tabs.TabPane>

        <Tabs.TabPane
          tab={
            <span style={{ backgroundColor: '#F8F8F8', padding: '5px' }}>
              <UsergroupAddOutlined /> Select Groups
            </span>
          }
          key="groups"
        >
          <div className="instant-email-form-header">
            <Form.Item name="groupList" label="Select groups" className="form-group w-[400px] pt-3">
              <Select
                mode="multiple"
                data-test="group-select"
                style={{ width: '100%' }}
                placeholder={'Select Group'}
                onChange={handleChange}
                filterOption={(input, option) => option.label.toLowerCase().includes(input.trim().toLowerCase())}
              >
                {filteredGroups?.map((data) => (
                  <Option 
                    key={data?._id} 
                    data-test="group-option"
                    value={data?._id} 
                    label={data.title}
                  >
                    <div className="demo-option-label-item">{data.title}</div>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <div>
              <p 
                onClick={() => setImVisible({ id: 'createNewContactGroup' })}  
                data-test="create-group-button" 
                className="instant-email-create-group"
              >
                Create new group
              </p>
            </div>
          </div>
        </Tabs.TabPane>

        <Tabs.TabPane
          tab={
            <span style={{ backgroundColor: '#F8F8F8', padding: '5px' }}>
              <UsergroupAddOutlined /> Add Recipient Using Both
            </span>
          }
          key="both"
        >
          <div className="instant-email-form-header">
            <Form.Item name="groupList" label="Select groups" className="form-group w-[400px] pt-3">
              <Select
                mode="multiple"
                data-test="both-group-select"
                style={{ width: '100%' }}
                placeholder={'Select Group'}
                onChange={handleChange}
                filterOption={(input, option) => option.label.toLowerCase().includes(input.trim().toLowerCase())}
              >
                {filteredGroups?.map((data) => (
                  <Option 
                    key={data?._id} 
                    data-test="both-group-option"
                    value={data?._id} 
                    label={data.title}
                  >
                    <div className="demo-option-label-item">{data.title}</div>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <div>
              <p 
                onClick={() => setImVisible({ id: 'createNewContactGroup' })} 
                className="instant-email-create-group"
              >
                Create new group
              </p>
            </div>
          </div>
          <div style={{ width: '300px' }}>
            <div className="flex items-center gap-1 my-1">
              <MdTypography variant={'btnRegular'}>Add email address</MdTypography>
              <span className="text-[#F2F2F2]">
                (Single or multiple) <span className={`${emailLimit >= 100 ? 'text-red-400' : null}`}>{emailLimit}/100</span>
              </span>
            </div>
            <div className={`${emailLimit >= 100 ? 'border-2 border-red-400 rounded-md' : 'border border-black'} flex flex-wrap`}>
              {addedMail?.length !== 0 && (
                <div className="flex flex-wrap gap-1 p-1">
                  {addedMail?.map((item, index) => (
                    <p
                      key={index}
                      className={`${validMail ? null : 'bg-red-400'} p-1 flex items-center border rounded-sm`}
                      style={{ backgroundColor: '#F2F2F2' }}
                    >
                      {item}
                      <span 
                        onClick={() => removeSingleMail(index)} 
                        className="mx-2 flex items-center mt-1"
                        data-test="both-close-email"
                      >
                        <Cancels height={20} width={20} />
                      </span>
                    </p>
                  ))}
                </div>
              )}
              <input
                onChange={handleMailData}
                value={emailData}
                placeholder="Enter email addresses separated by commas"
                className="w-full"
                readOnly={emailLimit >= 100}
                ref={inputRef}
                data-test="both-email-input"
              />
              <span className={`px-3 ${validMail || emailData === '' ? 'hidden text-[12px]' : 'block text-red-400 text-[8px]'}`}>
                Enter a valid mail
              </span>
            </div>
            <div
              className=" my-2 p-2 rounded-md"
              style={{ fontSize: '11px', backgroundColor: '#FFF9E6', border: '1px solid #FFECB2' }}
            >
              <p>
                Recipients that do not belong to any contact group will be automatically added to “All contacts” and will be
                visible in your saved contacts
              </p>
            </div>
          </div>
        </Tabs.TabPane>
      </Tabs>

      <div className="flex justify-between items-end">
        <MdTypography variant={'btnRegular'} className="mt-4 or">
          Total Subscribers: <span data-test="subscribers-count">{subscribersCount}</span>
        </MdTypography>
        <div className={`instant-email-form-button-header m-0 ${disable ? '' : 'button-opacity'}`}>
          <MdGlobalButton
            dataTestId="subscribers-save-btn"
            size={'large'}
            htmlType="submit"
            loading={savedGroupsToInstantEmailsLoading}
            disabled={!disable}
          >
            Done
          </MdGlobalButton>
        </div>
      </div>
    </Form>
  );
};

export default Step1Subscribers;