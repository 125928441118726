import { Input, Modal, Form } from 'antd';
import React from 'react';
import styles from '../../styles/share.module.scss';
import { useSenderEmailVerificationMutation, useGetUserDetailsQuery } from 'redux/featureApi/userApiSlice';
import VerifyModal from 'components/Modal/VerifyModal';
import { showToastMessage } from 'utils/Toast';
import { mustContainAtLeastOneChar } from 'utils/formRules';
import { MdGlobalButton, MdTypography } from 'components/global';


const AddSenderEmailModal = ({ open, setIsModalOpen }) => {
  const [email, setEmail] = React.useState('');
  const [verifyModalOpen, setVerifyModalOpen] = React.useState(false);

   const { data, isSuccess } = useGetUserDetailsQuery(
     {},
     {
       refetchOnMountOrArgChange: true,
     }
   );

  const userdata = isSuccess && data?.data;
  const existingSenderEmail = userdata?.workSpaces[0]?.owner?.verifiedEmails;

  const [addSenderEmail] = Form.useForm();

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const [addEmail, {isLoading: isAddEmail}] = useSenderEmailVerificationMutation();

  const onSubmit = async () => {
    existingSenderEmail?.includes(email) &&
      showToastMessage({
        type: 'error',
        title: 'Error',
        description: 'This email exists in your list of sender email already.',
      });
    
      try {
        await addEmail({
          email: email,
        })
          .unwrap()
          .then((res) => {
            setIsModalOpen(false);
            setVerifyModalOpen(true);
            showToastMessage({
              type: 'success',
              title: 'Success',
              description: 'Verification mail was sent successfully',
            });
          });
      } catch (error) {
        console.log(error?.data?.error)
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: error?.data?.error || 'An error occured',  
        });
      }
    
  };

  return (
    <div>
      <VerifyModal open={verifyModalOpen} email={email} handleClick={onSubmit} setIsModalOpen={setVerifyModalOpen} />

      <Modal
        // title="Add Sender Email Address"
        centered
        className={`${styles.modalContainer}  emmaccen`}
        open={open}
        onOk={''}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
      >
        <MdTypography 
        variant={'h5Medium'} className="text-center title mb-5">
          Add Sender Email Address
        </MdTypography>
        <Form 
        form={addSenderEmail} 
        name="basic" 
        onFinish={onSubmit}
        onFinishFailed={() => null} 
        style={{ width: '100%' }}
        >         
            <label style={{ color: '#555' }} className="add-sender-email-input mb-5 mt-5 ">
              Email Address
            </label>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Please provide an email address',
                  whitespace: true,
                },
                {
                  validator(_, value) {
                    if (!value.includes('yahoo') || !value.includes('gmail')) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Gmail or yahoo mail not allowed!'));
                    
                  },
                },  
                {...mustContainAtLeastOneChar},
              ]}
            >
              <Input
                className="add-sender-email-input"
                value={email}
                data-test="addSenderEmailInput"
                onChange={handleChange}
                placeholder="Enter Sender’s Email Address"
                type="email"
              />
              {/* {error && <p className="text-red mt-10">Gmail or yahoo mail is not allowed.</p>} */}
            </Form.Item>
            <MdGlobalButton
            dataTestId="addSenderEmailButton"
            htmlType='submit'
            id={'primary_lg'}
            loading={isAddEmail}
            type='primary'
            >
              <MdTypography 
              className="text-white">
                  Verify Email Address
              </MdTypography>
            </MdGlobalButton>
        </Form>
      </Modal>
    </div>
  );
};

export default AddSenderEmailModal;
