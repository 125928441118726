import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEditInstantEmailMutation, useGetSingleInstantEmailQuery } from 'redux/featureApi/instantEmailApiSlice';
import { showToastMessage } from 'utils/Toast';
import { instantEmailsActionTypes } from 'utils/constants';
// import { errorMessage } from 'utils/getErrors';
import TemplateSelection from './TemplateSelection';
// import { BreadCrumb } from 'components/emmaccen/sharedComponents/BreadCrumb';
import { MdTypography } from 'components/global';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useEditStrippoEmailMutation } from 'redux/featureApi/stripoApiSlice';

const InstantEmailSubject = ({ mailId }) => {
  // eslint-disable-next-line no-unused-vars
  const [submissionDisabled, setSubmissionDisabled] = useState(false);

  const location = useLocation();
  const { search } = location;
  const mailTitle = location?.state?.mailTitle;

  // const crumbs = [
  //   { name: 'Dashboard', link: '/dashboard' },
  //   { name: 'Instant Emails', link: '/instant-emails' },
  //   { name: mailTitle, link: `/instant-emails/drafts/${mailId}` },
  //   { name: 'Email builder' },
  // ];

  const navigate = useNavigate();
  const [selectedPlateObj, setSelectedPlateObj] = useState({});

  const typeOfMailParam = new URLSearchParams(search).get('type');

  const [InstantEmailMailBuilder, { isLoading: isEditingEmail }] = useEditInstantEmailMutation();
  const [EmailEditor] = useEditStrippoEmailMutation();

  const { data: getSingleInstantEmailData, isFetching: fetchingSingleInstantEmailData } = useGetSingleInstantEmailQuery(
    { mailId },
    { skip: !mailId }
  );

  // useEffect(() => {
  //   if (mailEdited) {
  //     const email = data.email;
  //     const route = `/instant-emails/${email._id}/${typeOfMailParam ?? 'web-builder'}?action=${
  //       instantEmailsActionTypes.create
  //     }`;
  //     if (selectedPlateObj?.key === 'upload-code')
  //       navigate(`${route}&key=${selectedPlateObj?.key}`, { state: { mailTitle } });
  //     else navigate(route, { state: { mailTitle } });
  //   } else if (mailEditReqFailed) {
  //     console.log(mailEditReqErr);
  //     showToastMessage({
  //       type: 'error',
  //       title: 'Error',
  //       description: 'Something went wrong, please try again later',
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isEditingEmail]);

  const submitTemplateSelected = async (temp) => {
    if (temp === null) {
      showToastMessage({
        type: 'error',
        title: 'Error',
        description: 'Kindly select a template to continue',
      });
      return;
    }
    const { body, content, typeOfMail, title, key, isFirst, isSaved, emptyTemplate } = temp;
    const route = `/instant-emails/${mailId}/${typeOfMailParam ?? 'web-builder'}?action=${
      instantEmailsActionTypes.create
    }`;
    const updateInstantEmail = async (selection) => {
      let selectTemplate = selection ? true : false;
      const resp = await InstantEmailMailBuilder({
        mailId: mailId,
        body: {
          body,
          content,
          typeOfMail,
          selectTemplate,
          emptyTemplate,
        },
      }).unwrap();
      return resp;
    };
    const updateStrippoEmail = async () => {
      const resp = await EmailEditor({
        mailId,
        body: { typeOfMail, strippoHtml: body, strippoCss: content },
      }).unwrap();
      return resp;
    };
    const selectFirst = true;
    isFirst
      ? updateInstantEmail(selectFirst)
          .then((resp) => {
            updateStrippoEmail().then(() => navigate(route, { state: { mailTitle, emptyTemplate, temp } }));
          })
          .catch((err) => {
            showToastMessage({
              type: 'error',
              title: 'Error',
              description: err?.message || 'An error occured, please try again',
            });
          })
      : isSaved
      ? updateStrippoEmail().then((resp) => {
          console.log({ resp });
          navigate(route, { state: mailTitle });
        })
      : updateInstantEmail()
          .then((resp) => {
            if (title || key) {
              navigate(`${route}&template=${title}&sub=${key}`, { state: { mailTitle, emptyTemplate, temp } });
              return;
            }
            if (selectedPlateObj?.key === 'upload-code') {
              navigate(`${route}&key=${selectedPlateObj?.key}`);
              return;
            } else {
              navigate(route, { state: { mailTitle, emptyTemplate, temp } });
            }
          })
          .catch((err) => {
            showToastMessage({
              type: 'error',
              title: 'Error',
              description: err?.message || 'An error occured, please try again',
            });
          });
  };

  return (
    <>
      <div className="boomshakar builder-select-template">
        {/* <BreadCrumb
          crumbs={crumbs}
          addClass={'builderBreadCrumbContainer'}
        /> */}
        {fetchingSingleInstantEmailData ? (
          <Spin indicator={<LoadingOutlined />} />
        ) : (
          <MdTypography variant={'h4Medium'} className={'select-template-header'}>
            {getSingleInstantEmailData?.data?.email?.title}
          </MdTypography>
        )}
        <TemplateSelection
          selectedPlateObj={selectedPlateObj}
          setSelectedPlateObj={setSelectedPlateObj}
          submitTemplateSelected={submitTemplateSelected}
          submissionIsLoading={isEditingEmail}
          submissionDisabled={submissionDisabled}
          typeOfMailParam={typeOfMailParam}
          isLoading={isEditingEmail}
        />
      </div>
    </>
  );
};

export default InstantEmailSubject;
