import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from '../../styles/optInPage.module.scss';
import blank from '../../asset/Icons/deer.svg';
import { Button, Input, Form, Typography } from 'antd';
import { FacebookIcon } from '../../asset/Icons/FacebookIcon';
import { InstagramIcon } from '../../asset/Icons/InstagramIcon';
import { TwitterIcon } from '../../asset/Icons/TwitterIcon';
import { LinkedinIcon } from '../../asset/Icons/LinkedinIcon';
import TiktokLogo from '../../asset/Icons/TiktokLogo';
import { Youtube } from '../../asset/Icons/Youtube';
import {
  useAddContactToOptInPageMutation,
  useGetSpecificOptInPageQuery,
} from '../../redux/featureApi/optInPageApiSlice';
import LoadingScreen from 'components/LoadingScreen';
import { Formik } from 'formik';
import { containValidEmail, mustBeValidUrl } from 'utils/formRules';
import { showToastMessage } from 'utils/Toast';
import { getContrastColor } from 'utils/miniFunctions/getColors';
import { StopOutlined } from '@ant-design/icons';
import HideFormillaChatBox from 'utils/miniFunctions/HideFormillaChatBox';

const { Paragraph } = Typography;

const OptInPagePreview = () => {
  const { optInPageId } = useParams();

  const [previewForm] = Form.useForm();

  const {
    data: getOptInPageData,
    isLoading,
    isFetching,
    isError: getOptInPageError,
  } = useGetSpecificOptInPageQuery(optInPageId);
  const [addContactToPage] = useAddContactToOptInPageMutation();
  const optInPage = getOptInPageData?.data;

  const [inputColor, setInputColor] = useState('#333');

  const handleColor = (event) => {
    // Change color based on input length
    if (event.target.value.length > 5) {
      setInputColor('#333');
    }
  };

  const handleSubmit = async ({ e, ...rest }, { setSubmitting, setFieldError, resetForm }) => {
    setSubmitting(true);
    const payload = {
      ...rest,
    };

    await addContactToPage({ payload, optInPageId })
      .unwrap()
      .then((e) => {
        showToastMessage({
          type: 'success',
          title: 'Success',
          description: 'Your email has been added, you can unsubscribe at any time',
        });
        resetForm({});
        setSubmitting(false);

        const redirectUrl = optInPage?.button?.redirect_link;
        if (redirectUrl !== '' && mustBeValidUrl[0].pattern.test(redirectUrl)) {
          window.location.replace(redirectUrl);
        }
      })
      .catch((error) => {
        console.log({ error });
        setSubmitting(false);
        if (error.response?.data?.message === 'Email already exist in campaign')
          setFieldError('email', 'Email already exist in campaign');
        else
          showToastMessage({
            type: 'error',
            title: 'Error',
            description: error?.data?.error || error?.data?.message || 'An error occured',
          });
      });
  };

  const handleValidation = (values) => {
    const errors = {};

    optInPage?.form_fields
      ?.filter((field) => field.enabled)
      .forEach((field) => {
        // check each value if empty
        if (!field.id.includes('email') && field.required && !values[field.id]) {
          errors[field.id] = `Please enter your ${field.name}`;
        }
        // check email if valid
        if (field.id.includes('email') && field.required && !containValidEmail[0].pattern.test(values[field.id])) {
          errors[field.id] = `Please enter a valid email address`;
        }
      });
    return errors;
  };

  useEffect(() => {
    if (getOptInPageError)
      return showToastMessage({
        type: 'error',
        title: 'Error',
        description: 'An error occured',
      });
  }, [getOptInPageError]);

  return (
    <div>
      <HideFormillaChatBox />
      {isLoading || isFetching ? (
        <LoadingScreen />
      ) : !optInPage?.published ? (
        <div className="restricted">
          <div className="restricted-inner">
            <StopOutlined style={{ color: 'red', fontSize: '3rem', marginBottom: '1rem' }} />
            <div className={styles.heading}> Restricted, Page isn't live!</div>
            <p className={`${styles.design_body} text-md`}>
              Sorry you can't view this page because the owner of this page has taken it down or is yet to publish it.
              Kindly reach out to the owner of the link. And if you're the owner, kindly go to your dashboard and
              publish your optIn page to view it live!
            </p>
          </div>
        </div>
      ) : (
        <div className={styles.preview_second_child}>
          <div style={{ background: optInPage?.banner_color }} className={styles.announcement}>
            <div style={{ overflow: 'hidden', width: '80px', height: '80px' }}>
              <img
                style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                src={optInPage?.logo || '/favicon.ico'}
                alt=""
              />
            </div>
          </div>
          <div className={styles.flex_container} style={{ backgroundColor: optInPage?.background_color }}>
            <div style={{ overflow: 'hidden', width: '100%', height: '100%', borderRadius: '8px' }}>
              <img
                style={{ objectFit: 'cover', width: '100%', height: '100%', borderRadius: '8px' }}
                src={optInPage?.hero_image || blank}
                alt=""
              />
            </div>

            <div className={styles.preview_design_content}>
              <Paragraph className={styles.heading}>{optInPage?.heading}</Paragraph>
              <p className={styles.design_body}>
                <Paragraph className={styles.design_body}>{optInPage?.body}</Paragraph>
              </p>
              {/* {containsMail ? ( */}
              <Formik
                initialValues={{
                  // Initialize other fields with empty strings
                  ...optInPage?.form_fields.reduce((acc, field) => {
                    return { ...acc, [field.id]: '' };
                  }, {}),
                }}
                validateOnMount
                onSubmit={handleSubmit}
                validate={handleValidation}
              >
                {({ values, handleChange, handleBlur, handleSubmit, isSubmitting, errors, touched, isValid }) => {
                  return (
                    <Form
                      form={previewForm}
                      name="basic"
                      onFinish={handleSubmit}
                      size="large"
                      onFinishFailed={() => null}
                      className="flex flex-col"
                    >
                      <div className={styles.form}>
                        {optInPage?.form_fields?.map((field) => {
                          return (
                            <Form.Item
                              name={field?.name}
                              rules={
                                [
                                  field?.name.toLowerCase().includes('email')
                                  &&
                                {
                                  required: true,
                                  message: 'Please input a valid email e.g example@gmail.com',
                                  type: 'email',
                                },
                                ]
                                // : [
                                //     {
                                //       required: true,
                                //       message: `${field?.name} cannot be blank`,
                                //     },
                                //     ...min3char,
                                //     ...mustHaveLetters,
                                //     ...containAzDchar,
                                //   ]
                              }
                            >
                              <Input
                                className={styles.input}
                                style={{ color: inputColor }}
                                data-test={`opt-in-page-form-${field?.id}`}
                                value={values[field?.id]}
                                placeholder={field?.placeholder}
                                name={field?.id}
                                initialValues={{}}
                                onChange={(event) => {
                                  handleChange(event);
                                  handleColor(event);
                                }}
                                onBlur={handleBlur}
                                onFocus={handleBlur}
                              // onChange={(e) => setFormName(e.target.value)}
                              />
                            </Form.Item>
                          );
                        })}
                      </div>

                      <Button
                        style={{ background: optInPage?.button?.background_color }}
                        className={styles.subscribe_button}
                        disabled={isSubmitting || !isValid}
                        htmlType="submit"
                        loading={isSubmitting}
                      >
                        {optInPage?.button?.title}
                      </Button>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
          <div style={{ background: optInPage?.footer_color }} className={styles.footer}>
            <div className={styles.margin_left}>
              <p style={{ color: getContrastColor(optInPage?.footer_color) }}>
                {optInPage?.footer_fields?.business_mail}
              </p>
              <p style={{ color: getContrastColor(optInPage?.footer_color) }}>
                {optInPage?.footer_fields?.phone_number}
              </p>
            </div>
            <div className={styles.social_media_icons}>
              {optInPage?.social_platforms?.facebook && (
                <a href={optInPage?.social_platforms?.facebook} rel="noreferrer" target="_blank">
                  <FacebookIcon />
                </a>
              )}
              {optInPage?.social_platforms?.instagram && (
                <a href={optInPage?.social_platforms?.instagram} rel="noreferrer" target="_blank">
                  <InstagramIcon />
                </a>
              )}
              {optInPage?.social_platforms?.twitter && (
                <a href={optInPage?.social_platforms?.twitter} rel="noreferrer" target="_blank">
                  <TwitterIcon />
                </a>
              )}
              {optInPage?.social_platforms?.linkedIn && (
                <a href={optInPage?.social_platforms?.linkedIn} rel="noreferrer" target="_blank">
                  <LinkedinIcon />
                </a>
              )}
              {optInPage?.social_platforms?.youtube && (
                <a href={optInPage?.social_platforms?.youtube} rel="noreferrer" target="_blank">
                  <Youtube />
                </a>
              )}
              {optInPage?.social_platforms?.tiktok && (
                <a href={optInPage?.social_platforms?.tiktok} rel="noreferrer" target="_blank">
                  <TiktokLogo />
                </a>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OptInPagePreview;
