import axios from 'axios';
import React, { useState } from 'react';
// import { ZoomInOutlined } from '@ant-design/icons';
import PreviewTemplate from './PreviewTemplate';
import { Button } from 'antd';
import { MdEmptyBox } from 'pages/ContactPage/Icons/MdEmptyBox';
import { showToastMessage } from 'utils/Toast';

const TemplateCard = ({
  id,
  currentCard,
  icon,
  title,
  savedTemplate,
  onClick,
  submitTemplateSelected,
  isGrid,
  temp,
  loading,
  setLoading,
  isPreviewVisible = true,
  emptyTemplate = false,
  dataTest,
}) => {
  const [modalvisible, setModalVisible] = useState(false);
  const [data, setData] = useState();

  const getHtmlTemplate = async (temp) => {
    if (temp?.fetchedTemplate && temp?.fetchedTemplate === true) {
      try {
        const templateRes = await axios.get(`/templates/${id}`);

        if (templateRes.status !== 200) {
          showToastMessage({
            type: 'error',
            title: 'Error',
            description: 'An error occurred trying to load preview, Please try again later.',
            duration: 8,
          });

          return;
        }

        const template = templateRes.data.data;

        return template;
      } catch (err) {
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: 'An error occurred trying to load preview, Please try again later.',
          duration: 8,
        });
      }
    } else {
      try {
        const htmlRes = await fetch(temp?.body, {
          headers: {},
          method: 'GET',
          body: null,
        });
        const cssRes = await fetch(temp.content, {
          headers: {},
          method: 'GET',
          body: null,
        });
        if (!htmlRes?.ok || !cssRes.ok) {
          showToastMessage({
            type: 'error',
            title: 'Error',
            description: 'An error occurred trying to load preview, Please try again later.',
            duration: 8,
          });

          return;
        }
        const html = await htmlRes.text();
        const css = await cssRes.text();
        return { html, css };
      } catch (error) {
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: 'An error occurred trying to load preview, Please try again later.',
          duration: 8,
        });
      }
    }
  };

  const previewHtmlTemplate = async (temp) => {
    setLoading({ id: 'preview' });
    await getHtmlTemplate(temp).then((x) => {
      setLoading({ id: '' });
      setData({ html: x.html, css: x.css });
      setModalVisible(true);
    });
  };
  const previewSavedTemplate = async (temp) => {
    setData({ html: temp.body, css: temp.content });
    setModalVisible(true);
  };
  const selectSavedTemplate = async (temp) => {
    setLoading({ id: 'select' });
    await submitTemplateSelected(temp);
  };
  const selectHtmlTemplate = async (temp) => {
    setLoading({ id: 'select' });
    if (temp !== undefined) {
      await getHtmlTemplate(temp).then((x) => {
        submitTemplateSelected({ ...temp, body: x?.html, content: x?.css });
      });
    }
  };

  const isCurrentCard = id === currentCard;

  return (
    <div
      className={`template-card ${isGrid ? 'w-100' : 't-card'} flex flex-wrap ${isCurrentCard && 'active'}`}
      data-test={dataTest}
    >
      <div className="temp_card_contain text-center w-full h-full flex justify-center items-center" onClick={onClick}>
        <>
          <img src={icon} alt="new-template" className="temp_img" />

          <MdEmptyBox />
          <div className="temp_overlay">
            <div className="temp_inner">
              <Button
                className="preview-templates-btn fill"
                data-test="create-new-strippo-card-button"
                onClick={() => {
                  const payload = { ...temp, emptyTemplate };
                  if (savedTemplate === true || emptyTemplate === true) return selectSavedTemplate(payload);
                  selectHtmlTemplate(payload);
                }}
                loading={loading?.id === 'select'}
              >
                Select
              </Button>
              {isPreviewVisible && (
                <Button
                  className="preview-templates-btn outline"
                  onClick={() => {
                    savedTemplate ? previewSavedTemplate(temp) : previewHtmlTemplate(temp);
                  }}
                  loading={loading?.id === 'preview'}
                >
                  Preview
                </Button>
              )}
            </div>
          </div>
          {modalvisible && (
            <PreviewTemplate
              title={title}
              icon={icon}
              visible={modalvisible}
              temp={data}
              setVisible={() => setModalVisible(false)}
            />
          )}
        </>
      </div>
    </div>
  );
};

export default TemplateCard;
