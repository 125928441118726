import React, { useState, useEffect, useContext } from 'react';
import styles from '../../styles/optInPage.module.scss';
import { Button, Steps, theme } from 'antd';
import Design from './Design';
import FormField from './FormField';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Share from './Share';
import SuccessModal from './SuccessModal';
import { useGetSpecificOptInPageQuery, useTogglepublishStatusMutation } from 'redux/featureApi/optInPageApiSlice';
import { showToastMessage } from 'utils/Toast';
import { MdGlobalButton, MdTypography } from 'components/global';
import { useGetAllContactGroupsQuery } from 'redux/featureApi/contactApiSlice';
import { joinCampaignContext } from 'contexts/joincampaign';
import { FORM_FIELDS } from 'contexts/joincampaign/reducer/types';
import LoadingScreen from 'components/LoadingScreen';
import { errorMessage } from 'utils/getErrors';
import { BackbuttonIcon } from '../../asset/Icons/Backbutton';

const OptInPageName = () => {
  const { state, dispatch } = useContext(joinCampaignContext);
  const { search } = useLocation();
  const navigate = useNavigate();
  const templateId = new URLSearchParams(search).get('template_id');
  const { formFields } = state;
  const { optInPageId, optInPageName } = useParams();
  const {
    data: getOptInPageData,
    isFetching: optInPageFetching,
    isError: optInPageError,
    // refetch: refetchOptInPageData,
  } = useGetSpecificOptInPageQuery(optInPageId, { refetchOnMountOrArgChange: true });
  const [file, setFile] = useState();
  const [heroFile, setHeroFile] = useState();
  const [heroErrorMessage, setHeroErrorMessage] = useState('Max size: 12mb');
  const [formName, setFormName] = useState('');
  const [buttonTitle, setButtonTitle] = useState();
  const [errorMessages, setErrorMessages] = useState('Max size: 12mb');
  const [backgroundColor, setBackgroundColor] = useState();
  const [bannerColor, setBannerColor] = useState();
  const [footerColor, setFooterColor] = useState();
  const [buttonColor, setButtonColor] = useState();
  const [editableStr, setEditableStr] = useState();
  const [editableText, setEditableText] = useState();
  const [fb, setFb] = useState(false);
  const [tweet, setTweet] = useState(false);
  const [insta, setInsta] = useState(false);
  const [linked, setLinked] = useState(false);
  const [youtub, setYoutub] = useState(false);
  const [tiktok, setTiktok] = useState(false);
  const [businessMail, setBusinessMail] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(false);
  const [businessMailLink, setBusinessMailLink] = useState(false);
  const [phoneNumberLink, setPhoneNumberLink] = useState(false);
  const [fbLink, setFbLink] = useState('');
  const [tweetLink, setTweetLink] = useState('');
  const [instaLink, setInstaLink] = useState('');
  const [linkedLink, setLinkedLink] = useState('');
  const [youLink, setYouLink] = useState('');
  const [tikLink, setTikLink] = useState('');
  const [redirectLink, setRedirectLink] = useState('');
  const [selectContact, setSelectedContact] = useState([]);
  const [contactGroups, setContactGroups] = useState([]);

  const webUrl = window.location.origin;
  const link = `${webUrl}/${optInPageName}/${optInPageId}`;
  const defaultFields = [
    {
      id: 'first_name',
      name: 'First Name',
      placeholder: 'First Name',
    },
    {
      id: 'last_name',
      name: 'Last Name',
      placeholder: 'Last Name',
    },
    {
      id: 'email_address',
      name: 'Email Address',
      placeholder: 'Email Address',
      required: true,
      enabled: false,
      deletable: false,
    },
  ];

  //get all contacts group with rtk
  const {
    data: getAllContactGroupsData,
    isLoading: fetchingGroups,
    isFetching,
    refetch,
    isError: contactGroupsError,
  } = useGetAllContactGroupsQuery();

  useEffect(() => {
    if (getOptInPageData) {
      const { data } = getOptInPageData;
      setFile({ src: data?.logo });
      setBackgroundColor(data?.background_color || '#544565');
      setBannerColor(data?.banner_color || '#C7EACC');
      setFooterColor(data?.footer_color || '#C7EACC');
      setEditableStr(data?.heading || 'Nice Heading Goes Here');
      setEditableText(
        data?.body ||
          'Other texts e.g A brief introduction explaining the purpose of your opt-in page and the benefits for the customer.Tip: Use concise bullet points or a short paragraph for easy readability.'
      );
      setTweetLink(data?.social_platforms.twitter);
      setLinkedLink(data?.social_platforms.linkedIn);
      setFbLink(data?.social_platforms.facebook);
      setInstaLink(data?.social_platforms.instagram);
      setBusinessMailLink(data?.footer_fields?.business_mail);
      setPhoneNumberLink(data?.footer_fields?.phone_number);
      setTikLink(data?.social_platforms.tiktok);
      setYouLink(data?.social_platforms.youtube);
      setButtonTitle(data?.button?.title || 'Subscribe');
      setButtonColor(data?.button?.background_color || '#018615');
      setRedirectLink(data?.button?.redirect_link);
      setHeroFile({ src: data?.hero_image });
      setFb(data?.social_platforms.facebook ? true : false);
      setTweet(data?.social_platforms.twitter ? true : false);
      setLinked(data?.social_platforms.linkedIn ? true : false);
      setInsta(data?.social_platforms.instagram ? true : false);
      setBusinessMail(data?.footer_fields?.business_mail ? true : false);
      setPhoneNumber(data?.footer_fields?.phone_number ? true : false);
      setTiktok(data?.social_platforms.tiktok ? true : false);
      setYoutub(data?.social_platforms.youtube ? true : false);
      // setSelectedContact(data?.contact_groups);

      dispatch({ type: FORM_FIELDS, payload: data?.form_fields ? data?.form_fields : defaultFields });
    } else if (optInPageError) {
      showToastMessage({
        type: 'error',
        title: 'Error',
        description: optInPageError?.data?.message || 'An error occured',
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optInPageFetching, getOptInPageData, optInPageError]);

  useEffect(() => {
    if (getAllContactGroupsData) {
      // Filter out groups with titles "All Contacts" and "Old Contacts"
      const filteredGroups = getAllContactGroupsData.groups.filter(
        (group) => group.title !== 'All Contacts' && group.title !== 'Risky Contacts'
      );
      setContactGroups(filteredGroups);
      // setSelectedContact();
    }
    if (getAllContactGroupsData && getOptInPageData) {
      const optInPageGroups = getOptInPageData.data.contact_groups.map((x) => x?._id);
      // Filter out groups with titles "All Contacts" and "Old Contacts"
      const foundContact = getAllContactGroupsData.groups.filter(
        (x) => optInPageGroups.includes(x?._id) && x.title !== 'All Contacts' && x.title !== 'Risky Contacts'
      );
      setSelectedContact(foundContact.map((x) => ({ id: x?._id, title: x?.title })));
    }
    if (contactGroupsError) {
      showToastMessage({
        type: 'error',
        title: 'Error',
        description: contactGroupsError?.data?.message || 'An error occured',
      });
    }
  }, [isFetching, getAllContactGroupsData, getOptInPageData, contactGroupsError]);

  const bannerString = React.useMemo(
    () => (typeof bannerColor === 'string' ? bannerColor : bannerColor?.toHexString()),
    [bannerColor]
  );

  const backgroundString = React.useMemo(
    () => (typeof backgroundColor === 'string' ? backgroundColor : backgroundColor?.toHexString()),
    [backgroundColor]
  );

  const footerString = React.useMemo(
    () => (typeof footerColor === 'string' ? footerColor : footerColor?.toHexString()),
    [footerColor]
  );

  const buttonString = React.useMemo(
    () => (typeof buttonColor === 'string' ? buttonColor : buttonColor?.toHexString()),
    [buttonColor]
  );

  const steps = [
    {
      title: 'Design',
      content: optInPageFetching ? (
        <LoadingScreen />
      ) : (
        <Design
          file={file}
          setFile={setFile}
          errorMessage={errorMessages}
          setErrorMessage={setErrorMessages}
          heroFile={heroFile}
          setHeroFile={setHeroFile}
          setHeroErrorMessage={setHeroErrorMessage}
          heroErrorMessage={heroErrorMessage}
          backgroundColor={backgroundString}
          buttonColor={buttonString}
          bannerColor={bannerString}
          footerColor={footerString}
          setBackgroundColor={setBackgroundColor}
          setBannerColor={setBannerColor}
          setFooterColor={setFooterColor}
          setButtonColor={setButtonColor}
          formName={formName}
          setFormName={setFormName}
          buttonTitle={buttonTitle}
          setButtonTitle={setButtonTitle}
          editableStr={editableStr}
          setEditableStr={setEditableStr}
          editableText={editableText}
          setEditableText={setEditableText}
          fb={fb}
          setFb={setFb}
          tweet={tweet}
          setTweet={setTweet}
          insta={insta}
          setInsta={setInsta}
          linked={linked}
          setLinked={setLinked}
          youtub={youtub}
          setYoutub={setYoutub}
          tiktok={tiktok}
          setTiktok={setTiktok}
          fbLink={fbLink}
          setFbLink={setFbLink}
          tweetLink={tweetLink}
          setTweetLink={setTweetLink}
          instaLink={instaLink}
          setInstaLink={setInstaLink}
          linkedLink={linkedLink}
          setLinkedLink={setLinkedLink}
          youLink={youLink}
          setYouLink={setYouLink}
          tikLink={tikLink}
          setTikLink={setTikLink}
          formFields={formFields}
          redirectLink={redirectLink}
          setRedirectLink={setRedirectLink}
          templateId={templateId}
          businessMail={businessMail}
          phoneNumber={phoneNumber}
          setBusinessMail={setBusinessMail}
          setPhoneNumber={setPhoneNumber}
          businessMailLink={businessMailLink}
          phoneNumberLink={phoneNumberLink}
          setBusinessMailLink={setBusinessMailLink}
          setPhoneNumberLink={setPhoneNumberLink}
        />
      ),
    },
    {
      title: 'Connect',
      content: (
        <FormField
          selectContact={selectContact}
          setSelectedContact={setSelectedContact}
          fetchingGroups={fetchingGroups}
          contactGroups={contactGroups}
          setContactGroups={setContactGroups}
          refetch={refetch}
        />
      ),
    },
    {
      title: 'Share',
      content: <Share link={link} />,
    },
  ];

  const contactGroup = selectContact.map((x) => {
    return x?.id;
  });

  // eslint-disable-next-line no-unused-vars
  const { token } = theme.useToken();

  const [current, setCurrent] = useState(0);
  const next = () => {
    setCurrent((prev) => prev + 1);
  };
  const prev = () => {
    if (current <= 0) {
      navigate(-1);
      return;
    }
    setCurrent((prev) => prev - 1);
  };
  const filteredStep = steps.filter((item) => item !== false);
  const items = filteredStep.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const [addPublishOptInPage, { isLoading: postingPublishOptInPage }] = useTogglepublishStatusMutation();

  const onSubmitOptInPageDetails = async () => {
    if (contactGroup.length === 0) {
      showToastMessage({
        type: 'error',
        title: 'Error',
        description: 'Please select a contact group to add leads to before you continue',
      });
      return;
    }
    try {
      await addPublishOptInPage({
        payload: {
          background_color: backgroundString,
          banner_color: bannerString,
          footer_color: footerString,
          heading: editableStr,
          body: editableText,
          logo: file?.src,
          twitter: tweet && tweetLink,
          linkedIn: linked && linkedLink,
          facebook: fb && fbLink,
          instagram: insta && instaLink,
          youtube: youtub && youLink,
          tiktok: tiktok && tikLink,
          business_mail: businessMail && businessMailLink,
          phone_number: phoneNumber && phoneNumberLink,
          button_text: buttonTitle,
          button_bg_color: buttonString,
          button_redirect_link: redirectLink,
          hero_image: heroFile?.src,
          form_fields: formFields,
          contact_groups: contactGroup,
          page_url: link,
          template_id: templateId,
        },
        optInPageId: optInPageId,
        type: 'publish',
      })
        .unwrap()
        .then(() => {
          showToastMessage({
            type: 'success',
            title: 'Opt-in Page Added',
            description: 'Opt-in Page added successfully',
          });
          next();
        });
    } catch (error) {
      const errMsg = errorMessage(error);
      showToastMessage({
        type: 'error',
        title: 'Error',
        description: errMsg,
      });
    }
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  return (
    <div className={styles.container}>
      <div className={styles.first_box}>
        <div className="flex justify-between w-full">

        <div className={styles.back_mobile_desktop}>
          <div onClick={() => prev()} className={styles.back}>
            <BackbuttonIcon/>
            <p>Go Back</p>
          </div>
        </div>


        
        <Steps   className={styles.antd_timeline} current={current} items={items} direction="horizontal" />
        </div>
        <div className={styles.button}>
          <>
            {current === 0 && (
              <Button onClick={() => next()} className={styles.next_button} data-test="create-opt-in-page-next">
                Done
              </Button>
            )}
            {current === 1 && (
              <MdGlobalButton
                type="primary"
                htmlType="submit"
                dataTestId="create-opt-in-page-next"
                loading={postingPublishOptInPage}
                onClick={onSubmitOptInPageDetails}
                className={styles.next_button}
              >
                <MdTypography variant={'h6Medium'} style={{ color: '#ffffff' }}>
                  Done
                </MdTypography>
              </MdGlobalButton>
            )}
            {current === 2 && (
              <Button onClick={showModal} className={styles.next_button}>
                Publish
              </Button>
            )}
          </>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.first_section}>{filteredStep[current]?.content}</div>
      </div>
      <SuccessModal link={link} open={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </div>
  );
};

export default OptInPageName;