import { useState, useEffect } from 'react';
import { Radio, Space, Input } from 'antd';
import { MdGlobalButton } from 'components/global';
import { useNavigate } from 'react-router-dom';
import 'styles/instantemails.scss';
import { useEditInstantEmailMutation, useGetSingleInstantEmailQuery } from 'redux/featureApi/instantEmailApiSlice';
import { showToastMessage } from 'utils/Toast';
import isValidForm from 'components/emmaccen/shared-functions/inputValidator';

const Step4DesignEmail = ({
  instantEmailTitle,
  email,
  mailId,
  mailTypes,
  emailSubject,
  setImVisible,
  instantEmailsActionTypes,
}) => {
  const { TextArea } = Input;
  const navigate = useNavigate();

  const [value, setValue] = useState(null);
  const [mail, setMail] = useState({
    subject: '',
    body: '',
    from: '',
    to: '',
    status: 1,
    duration: '',
    typeOfMail: 'simple-text-editor',
    emailInterval: '',
    attachments: [],
    imageURL: '',
  });

  const [
    InstantEmailTextEditor,
    {
      isLoading: isEditingEmail,
      isSuccess: isTextEditorSuccess,
      isError: isTextEditorError,
      // data,
      error: editingInstantEmailErrorMessage,
    },
  ] = useEditInstantEmailMutation();

  const {
    data: emailRes,
    isLoading: loadingEmailFetchReq,
    isSuccess: emailFetched,
    isError: emailFetchReqFailed,
    error: emailFetchReqErr,
  } = useGetSingleInstantEmailQuery({ mailId });

  useEffect(() => {
    if (emailFetched && emailRes?.data?.email.typeOfMail === 'simple-text-editor') {
      const { email } = emailRes?.data;
      const resanitizeBody = email?.body
        .replace(/<br\/>/g, '\n')
        .replace(/&nbsp;&nbsp;&nbsp;&nbsp;/g, '\t')
        .replace(/&nbsp;&nbsp;/g, '  ');
      setMail((prev) => ({
        ...prev,
        ...email,
        body: email?.body === '' ? '' : resanitizeBody,
      }));
    } else if (emailFetchReqFailed) {
      console.log(emailFetchReqErr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingEmailFetchReq]);

  const [disabledOne, setDisabled] = useState(false);
  const [disabledTwo, setDisabledTwo] = useState(false);
  const [checkedOne, setCheckedOne] = useState(false);
  const [checkedTwo, setCheckedTwo] = useState(false);

  useEffect(() => {
    if (value === null && emailRes.data.email.typeOfMail === undefined) {
      setDisabled(false);
      setDisabledTwo(false);
    } else if (
      value === 2 ||
      (emailRes.data.email.typeOfMail !== undefined && emailRes.data.email.typeOfMail !== 'simple-text-editor')
    ) {
      setDisabled(true) && setCheckedTwo(true);
      setValue(2);
    } else if (
      value === 1 ||
      (emailRes.data.email.typeOfMail !== undefined && emailRes.data.email.typeOfMail === 'simple-text-editor')
    ) {
      setDisabledTwo(true) && setCheckedOne(true);
      setValue(1);
    }
    if (isTextEditorSuccess) {
      showToastMessage({
        type: 'success',
        title: 'Email',
        description: 'Your mail has been updated successfully',
      });
    } else if (isTextEditorError) {
      const spamDetected = editingInstantEmailErrorMessage?.data?.error?.includes('spam');
      showToastMessage({
        type: 'error',
        title: spamDetected ? 'Spam Detected' : 'Oops',
        description: spamDetected
          ? editingInstantEmailErrorMessage?.data?.error
          : 'An error occurred, please try again',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTextEditorSuccess, isTextEditorError]);

  const handleEmailCreation = () => {
    const sanitizedBody = mail.body
      .replace(/\n/g, '<br/>')
      .replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;')
      .replace(/ {2}/g, '&nbsp;&nbsp;');
    if (isValidForm([mail.body])) {
      setMail({ ...mail });

      InstantEmailTextEditor({
        mailId: mailId,
        body: {
          subject: emailSubject || 'Message to {{FIRST_NAME}}',
          body: sanitizedBody,
          attachments: mail.attachments,
          typeOfMail: mailTypes.simpleText,
        },
      })
        .unwrap()
        .then((data) => {
          console.log({ data });
        })
        .catch((err) => console.log({ err }));
    } else {
      // setImLoading({ id: '' });
      showToastMessage({
        type: 'warning',
        title: 'Required Fields',
        description: 'Please provide both email-body and subject',
      });
    }
  };

  const onChange = (e) => {
    const val = e.target.value;
    setValue(val);
  };

  return (
    <div>
      <Radio.Group onChange={onChange} value={value} style={{ width: '100%' }}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <div style={{ width: '100%' }}>
            <Radio value={1} data-test="radio-button-one" checked={checkedOne} disabled={disabledOne}>
              <p className="step-4-p">Compose simple text email</p>{' '}
            </Radio>
            <div className="step-4-textarea-container">
              <TextArea
                className="step-4-textarea"
                data-test="design-email-simple-editor"
                rows={5}
                style={{ resize: 'none' }}
                placeholder="Write something "
                value={mail?.body}
                onChange={(e) => {
                  setMail((prev) => ({ ...prev, body: e.target.value }));

                  if (value === null) {
                    setValue(1);
                  }
                }}
              />
              <MdGlobalButton
                data-test="design-email-simple-editor-btn"
                className="design-email-button maildrip_btn"
                loading={isEditingEmail}
                disabled={
                  !(value === 1) ||
                  (emailRes.data.email.typeOfMail !== undefined &&
                    emailRes.data.email.typeOfMail !== 'simple-text-editor')
                }
                onClick={handleEmailCreation}
              >
                Done
              </MdGlobalButton>
            </div>
          </div>
          <div style={{ width: '100%', marginTop: '2rem' }}>
            <Radio data-test="radio-button-two" checked={checkedTwo} disabled={disabledTwo} value={2}>
              <p className="step-4-p">Use email builder</p>
            </Radio>
            <MdGlobalButton
              data-test="design-email-test-btn"
              className="design-email-builder"
              loading={false}
              disabled={!(value === 2)}
              onClick={() => {
                if (!email?.typeOfMail) setImVisible({ id: 'createEmailModals' });
                else if (email?.typeOfMail === mailTypes.textEditor)
                  navigate(`/instant-emails/${mailId}/text-editor?action=${instantEmailsActionTypes.create}`);
                else if (email?.typeOfMail === mailTypes.webBuilder)
                  navigate(`/instant-emails/${mailId}/web-builder?action=${instantEmailsActionTypes.create}`, {
                    state: { mailTitle: instantEmailTitle },
                  });
                else if (email?.typeOfMail === mailTypes.strippoEditor)
                  navigate(`/instant-emails/${mailId}/speditor?action=${instantEmailsActionTypes.create}`, {
                    state: { mailTitle: instantEmailTitle },
                  });
              }}
            >
              Select builder
            </MdGlobalButton>
          </div>
        </Space>
      </Radio.Group>
    </div>
  );
};

export default Step4DesignEmail;
