import React from 'react';
import '../../../styles/instantemails.scss';
import MdGlobalButton from '../../../components/global/Button';
import { MdTypography } from '../../../components/global';
import { DatePicker } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import dayjs from 'dayjs';

const Scheduler = ({ visible, onClose, onChangeCallback }) => {
  // eslint-disable-next-line no-unused-vars
  const [selectedDate, setSelectedDate] = useState({});
  const [selectedTime] = useState();
  const [dateFormat] = useState('YYYY-MM-DD HH:mm');

  const onChange = (date, dateString) => {
    const isoString = moment(dateString).toISOString();
    onChangeCallback(isoString);
  };

  return (
    <>
      <DatePicker
        open={visible}
        className="custom-picker"
        onSelect={(date) => setSelectedDate({ ...date })}
        format={dateFormat}
        showSecond={false}
        showNow={false}
        minuteStep={5}
        onChange={onChange}
        defaultValue={dayjs()}
        disabledDate={(current) => current.isBefore(moment().startOf('day').add(selectedTime, 'minutes'))}
        inputReadOnly
        showTime={{
          format: 'hh:mm',
        }}
        renderExtraFooter={() => (
          <MdGlobalButton
            id="primary_xs"
            dataTestId="schedule-closebtn-testId"
            className="mx-auto mt-5 mb-4"
            onClick={onClose}
          >
            <MdTypography variant={'btnRegular'}>Close</MdTypography>
          </MdGlobalButton>
        )}
      />
    </>
  );
};
export default Scheduler;
