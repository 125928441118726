import { useNavigate, useLocation } from 'react-router-dom';
import { showToastMessage } from 'utils/Toast';
import MdLandingPageTemplateSelection from './components/MdLandingPageTemplateSelection';
import { MdTypography } from 'components/global';
import styles from '../../styles/optInPage.module.scss';
import { useEditStrippoEmailMutation } from 'redux/featureApi/stripoApiSlice';

const MdSelectLandingPageTemplate = () => {
  const location = useLocation();
  const { title, landingPageId } = location.state;

  const navigate = useNavigate();

  const [EmailEditor] = useEditStrippoEmailMutation();

  const submitTemplateSelected = async (temp) => {
    if (temp === null) {
      showToastMessage({
        type: 'error',
        title: 'Error',
        description: 'Kindly select a template to continue',
      });
      return;
    }

    const { body, content, typeOfMail } = temp;
    const route = `/landing-pages/${landingPageId}/edit`;

    const createStrippoLandingPage = async () => {
      const resp = await EmailEditor({
        mailId: landingPageId,
        body: { typeOfMail, strippoHtml: body, strippoCss: content, isLandingPage: true },
      }).unwrap();
      return resp;
    };

    createStrippoLandingPage().then(() => navigate(route, { state: { title, temp } }));
  };

  return (
    <>
      <div className="boomshakar builder-select-template">
        <div className={styles['landing-page-action']}>
          <div className={styles['landing-page-action-header']}>
            <MdTypography className={styles['btnLight']} variant="btnLight">
              Design landing page
            </MdTypography>
            <MdTypography className={styles['pLight']} variant="pLight">
              Begin with a hand-full of pre-made templates
            </MdTypography>
          </div>
        </div>
        <MdLandingPageTemplateSelection submitTemplateSelected={submitTemplateSelected} typeOfMailParam={'speditor'} />
      </div>
    </>
  );
};

export default MdSelectLandingPageTemplate;
