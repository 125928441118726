import { apiAuthSlice } from './apiAuthSlice';

export const stripoApiSlice = apiAuthSlice.enhanceEndpoints({ addTagTypes: ['SpecificStrippoMail'] }).injectEndpoints({
  endpoints: (builder) => ({
    stripoAuthToken: builder.query({
      query: () => ({
        url: '/strippo/token',
        method: 'GET', 
      }),
    }),
    getBuilderTemplate: builder.query({
      query: (url) => ({
        url: url,
        method: 'GET',
        responseHandler: (response) => response.text(),
      }),
    }),
    createNewEmail: builder.mutation({
      query: (body) => ({
        url: '/strippo',
        method: 'POST',
        body,
      }),
    }),
    getStrippoMailData: builder.query({
      query: ({ mailId }) => ({
        url: `/strippo/${mailId}`,
        method: 'GET',
      }),
      transformResponse: (response) => response?.data,
      providesTags: ['SpecificStrippoMail'],
    }),
    editStrippoEmail: builder.mutation({
      query: ({ mailId, body }) => ({
        url: `/strippo/${mailId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['SpecificStrippoMail'],
    }),
    sendStrippoTestMail: builder.mutation({
      query: ({ emailId, body }) => ({
        url: `/instant-emails/send/${emailId}`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useStripoAuthTokenQuery,
  useLazyStripoAuthTokenQuery,
  useGetBuilderTemplateQuery,
  useCreateNewEmailMutation,
  useGetStrippoMailDataQuery,
  useEditStrippoEmailMutation,
  useSendStrippoTestMailMutation,
} = stripoApiSlice;