import { Button, Form, Input, Modal } from 'antd';
import { useState, useMemo } from 'react';
import { useCreateContactGroupMutation, useGetAllContactGroupsQuery } from 'redux/featureApi/contactApiSlice';
import 'styles/modals.scss';
import { showToastMessage } from 'utils/Toast';
import { MdTypography } from 'components/global';
import styles from 'styles/newContactPage.module.scss';
import { MdClose } from 'pages/ContactPage/Icons/MdClose';
import MdCsvReader from './MdCsvReader';
import MdConfirmCsvUpload from './MdConfirmCsvUpload';
import SuccessIcon from 'asset/Icons/SuccessIcon';

export const CreateNewGroup = ({
  imVisible,
  setImVisible,
  contactGroups,
  setCsvResult,
  refetchSingleInstantEmail,
  campaignId,
  csvResult,
  group,
  setGroup,
  saveContactForm,
}) => {
  const [uploadStep, setUploadStep] = useState(0);
  const [instantForm] = Form.useForm();
  const [csvValidationError, setCsvValidationError] = useState('');
  const [createContactGroup, { isLoading: creatingContactGroup }] = useCreateContactGroupMutation();
  const [groupDetails, setGroupDetails] = useState(null);

  const { refetch: refetchContactGroupsData } = useGetAllContactGroupsQuery();

  const handleButtonClick = () => {
    setUploadStep(1);
  };

  const validateCsv = useMemo(() => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return () => {
      if (!Array.isArray(csvResult) || !csvResult?.length) {
        showToastMessage({
          type: 'error',
          title: 'CSV Validation',
          description: 'Please upload a CSV file with contacts',
        });
        return false;
      }

      const validRows = csvResult.filter((row) => row && typeof row === 'object');
      if (validRows.length === 0) {
        showToastMessage({
          type: 'error',
          title: 'CSV Validation',
          description: 'No valid data found in CSV file',
        });
        return false;
      }

      const headers = Object.keys(validRows[0]);
      let hasValidEmailColumn = false;

      for (const header of headers) {
        let validEmails = 0;
        let totalNonEmpty = 0;

        for (const row of validRows) {
          const value = row[header]?.toString().trim();
          if (!value) continue;

          totalNonEmpty++;
          if (emailRegex.test(value)) {
            validEmails++;
          }
        }

        if (totalNonEmpty > 0 && validEmails / totalNonEmpty >= 0.9) {
          hasValidEmailColumn = true;
          break;
        }
      }

      if (!hasValidEmailColumn) {
        showToastMessage({
          type: 'error',
          title: 'CSV Validation',
          description: 'CSV must contain a column with valid email addresses',
        });
        return false;
      }

      setCsvValidationError('');
      return true;
    };
  }, [csvResult]);

  const onSubmitContactDetail = async ({ title, description = '' }) => {
    try {
      if (!validateCsv()) {
        return;
      }

      const normalizedTitle = title?.trim().toLowerCase();

      if (!normalizedTitle) {
        showToastMessage({
          type: 'error',
          title: 'Contact Group',
          description: 'Group name cannot be empty',
        });
        return;
      }

      if (normalizedTitle.length === 0 || normalizedTitle.replace(/\s/g, '').length === 0) {
        showToastMessage({
          type: 'error',
          title: 'Contact Group',
          description: 'Please enter a valid group name',
        });
        return;
      }

      const titleExists = contactGroups?.some((group) => group.title?.toLowerCase() === normalizedTitle);

      if (titleExists) {
        showToastMessage({
          type: 'error',
          title: 'Contact Group',
          description: 'Group with this title already exists',
        });
        return;
      }

      // Instead of creating the group now, store the details for later
      setGroupDetails({
        title: title.trim(),
        description: description.trim(),
      });

      handleButtonClick();
    } catch (error) {
      showToastMessage({
        type: 'error',
        title: 'Error',
        description: error?.message || 'Failed to validate group details',
      });
    }
  };

  const handleModalClose = () => {
    setUploadStep(0);
    setCsvResult([]);
    setCsvValidationError('');
    setGroupDetails(null);
    instantForm.resetFields();
  };

  const existingGroups = contactGroups.map((g) => g?.title?.trim())

  
  const validateGroupName = (_, value) => {
    if (!value) {
      // This will be handled by the required rule
      return Promise.resolve();
    }
    if (existingGroups.map((name) => name.trim().toLowerCase()).includes(value.trim().toLowerCase())) {
      return Promise.reject(new Error('This group name already exists!'));
    }
    return Promise.resolve();
  };

  return (
    <Modal
      className={`emmaccen ${styles['contact-modal']}`}
      open={imVisible.id === 'createNewContactGroup'}
      afterClose={handleModalClose}
      footer={null}
      maskClosable={false}
      onCancel={() => setImVisible({ id: '' })}
      closable={true}
      destroyOnClose={true}
      closeIcon={<MdClose />}
      centered
    >
      {uploadStep === 0 && (
        <Form
          form={instantForm}
          onFinish={onSubmitContactDetail}
          onFinishFailed={() => null}
          autoComplete="off"
          size="large"
          layout="vertical"
          requiredMark={false}
        >
          <div className={styles['addNewContactModal']}>
            <MdTypography variant="h5Medium" className="text-center title mb-8">
              Create new group
            </MdTypography>

            <Form.Item name="title" label="Group name" rules={[{ required: true, message: 'Please provide a title!' }, { validator: validateGroupName }]}>
              <Input placeholder="Enter group name" data-test="create-contact-grp-title-csv" maxLength={100} />
            </Form.Item>

            <Form.Item name="description" label="Description (optional)">
              <Input placeholder="Enter group description" data-test="create-contact-grp-desc" maxLength={500} />
            </Form.Item>

            <p className="instant-email-upload-contact">Upload contacts to your new group (required)</p>
            <MdCsvReader
              imVisible={imVisible}
              setImVisible={setImVisible}
              setCSVResult={setCsvResult}
              uploadContactsTo="createNewContactGroup"
              data-test="create-contact-grp-csv"
            />

            {csvValidationError && (
              <div className={styles['error-message']} style={{ color: 'red', marginTop: '8px' }}>
                {csvValidationError}
              </div>
            )}

            <div className={styles['info-box']}>
              Contacts in your uploaded CSV will be added to the specified group, and will be visible under your saved
              contacts. The CSV must contain an "email" column.
            </div>

            <div className="emmaccen">
              <div className="flex-space-btw actionBtns">
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  className={`${styles['contact-btn']} w-full mt-0 text-white`}
                  loading={creatingContactGroup}
                  disabled={creatingContactGroup || !csvResult?.length}
                  data-test="csv-contact-done"
                >
                  Proceed
                </Button>
              </div>
            </div>
          </div>
        </Form>
      )}

      {uploadStep === 1 && (
        <MdConfirmCsvUpload
          saveContactForm={saveContactForm}
          csvResult={csvResult}
          setCsvResult={setCsvResult}
          uploadContactsTo="createNewContactGroup"
          imVisible={imVisible}
          setImVisible={setImVisible}
          setUploadStep={setUploadStep}
          campaignId={campaignId}
          refetchContactGroupsData={refetchContactGroupsData}
          refetchSingleInstantEmail={refetchSingleInstantEmail}
          group={group}
          setGroup={setGroup}
          instantForm={instantForm}
          groupDetails={groupDetails}
          createContactGroup={createContactGroup}
        />
      )}

      {uploadStep === 2 && (
        <div
          style={{ height: '298px', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}
          className="text-center"
        >
          <div className="text-green flex justify-content-center align-items-center">
            <SuccessIcon />
          </div>
          <p style={{ fontFamily: 'Paralucent-Medium' }} className="font18 mt-7">
            New contact group created
          </p>
          <p style={{ fontFamily: 'Paralucent-Light' }} className="font14 mt-4">
            You can now select this group as part of your recipients for your instant email
          </p>
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            className={`${styles['contact-btn']} w-full mt-2 text-white`}
            loading={creatingContactGroup}
            data-test="contact-done-csv"
            onClick={() => setImVisible({ id: '' })}
          >
            Back to instant email
          </Button>
        </div>
      )}
    </Modal>
  );
};
