import { Modal, Tabs } from 'antd';
import TabPane from 'antd/es/tabs/TabPane';
import LoadingScreen from 'components/LoadingScreen';
import { MdTypography } from 'components/global';
import { MdEmptyBox } from 'pages/ContactPage/Icons/MdEmptyBox';
import TemplateCard from 'pages/InstantEmail/components/TemplateCard';
import emptyTableImg from '../../../asset/images/flat-color-icons_template.svg';
import React, { useEffect, useState } from 'react';
import { fetchTemplates } from '../utils';
import axios from 'axios';
import { MdClose } from 'pages/ContactPage/Icons/MdClose';
import { updateCategories } from 'pages/InstantEmail/components/TemplateSelection';

export const ChangeTemplateModal = ({
  setModalVisible,
  modalVisible,
  handleTemplateChange,
  setCurrentTemplate,
  landingPageId,
}) => {
  const [mySavedFetchTemplates, setMySavedFetchTemplates] = useState([]);
  const [loading, setLoading] = useState({ id: '' });
  const [isTemplateLoading, setIsTemplateLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [templateCategories, setTemplateCategories] = useState(updateCategories(items));
  const [currentCard] = useState(null)

  useEffect(() => {
    setIsTemplateLoading(true);
    (async () => {
      try {
        await fetchTemplates(setItems, setIsTemplateLoading, emptyTableImg);
        const resp2 = await axios.get(`/templates/user?type=${'speditor'}`);
        setIsTemplateLoading(false);
        if (resp2?.status) {
          setMySavedFetchTemplates(resp2.data.data);
          return;
        }
        setMySavedFetchTemplates([]);
      } catch (error) {
        console.log({ error });
      }
    })();
  }, []);
  
  useEffect(() => {
    setTemplateCategories(updateCategories(items));
  }, [items]);
  const onClose = () => setModalVisible({ id: '' });

  const selectTemplateAndClose = (temp) => {
    setCurrentTemplate({
      htmlTemplate: temp.body,
      cssTemplate: temp.content,
    });
    setLoading({ id: '' });
    onClose();
  };
  const selectMyTemplateAndClose = (temp) => {
    setCurrentTemplate({
      htmlTemplate: temp.body,
      cssTemplate: temp.content,
    });
    setLoading({ id: '' });
    onClose();
  };

  const myTemplates = mySavedFetchTemplates
  .reverse()
  .map((obj, index) => ({
    _id: index,
    typeOfMail: obj.typeOfMail,
    body: obj.strippoHtml,
    content: obj.strippoCss,
    previewImage: obj?.previewImage || emptyTableImg,
    isSaved: true,
  }));
  
  return (
    <Modal
      open={modalVisible.id === 'changeTemplate'}
      onOk={() => setModalVisible({ id: '' })}
      //   onCancel={() => setModalVisible({ id: '' })}
      footer={null}
      closable={false}
      centered
      className="stripo-temp"
      width={'65%'}
    >
      <div className="">
        <div className="flex justify-between mb-8">
          <div className="w-1/2">
            <MdTypography variant={'h5Medium'} className="">
              Email templates
            </MdTypography>
            <MdTypography variant={'h6Light'} className="text-sm mt-1">
              Select a template from the pre-made templates or select one of the templates you have created previously
            </MdTypography>
          </div>
          <MdClose onClick={onClose} cursor="pointer" />
        </div>
        <div className="preview_template">
          <Tabs>
            <TabPane tab={<MdTypography variant={'h6Light'}>Templates</MdTypography>} key="1">
              <div>
                {templateCategories.length < 1 ? (
                  <div className="w-full emptyBox">
                    <MdEmptyBox />
                    <div className="emptyText">
                      We are bringing in free reusable templates for you to create beautiful emails soon
                    </div>
                  </div>
                ) : (
                  templateCategories.map((category) => (
                    <div className="template-section" key={category}>
                      <div className="mb-3">
                        <MdTypography variant={'h6Medium'} className="capitalize">
                          {category}
                        </MdTypography>
                      </div>
                      <div className="mb-8 template-grid" >
                        {items
                          .filter((item) => item.category === category)
                          .map((temp, index) => (
                            <TemplateCard
                              key={index}
                              id={temp._id}
                              icon={temp.previewImage}
                              title={temp.title}
                              data-index={index}
                              submitTemplateSelected={selectTemplateAndClose}
                              temp={temp}
                              setLoading={setLoading}
                              loading={loading}
                              currentCard={currentCard}
                            />
                          ))}
                      </div>
                    </div>
                  ))
                )}
              </div>
            </TabPane>
            {!landingPageId && (
              <TabPane tab={<MdTypography variant={'h6Light'}>My Saved Templates</MdTypography>} key="2">
                {isTemplateLoading ? (
                  <LoadingScreen />
                ) : (
                  <>
                    {!mySavedFetchTemplates.length ? (
                      <div className="w-full emptyBox">
                        <MdEmptyBox />
                        <div className="emptyText">You have not created any template yet</div>
                      </div>
                    ) : (
                      <div className="mb-8 template-grid">
                        {myTemplates.map((temp, index) => {
                          return (
                            <TemplateCard
                              key={index}
                              id={temp._id}
                              icon={temp.previewImage}
                              title={temp.title}
                              data-index={index}
                              submitTemplateSelected={selectMyTemplateAndClose}
                              temp={temp}
                              savedTemplate={true}
                              setLoading={setLoading}
                              loading={loading}
                              currentCard={currentCard}
                            />
                          );
                        })}
                      </div>
                    )}
                  </>
                )}
              </TabPane>
            )}
          </Tabs>
        </div>
      </div>
    </Modal>
  );
};
